/* ==========================================================
 icon
========================================================== */
/* ico-new
---------------------------------------------------------- */
.ico-new {
  &:after {
    position: relative;
    top: 0;
    display: inline-block;
    padding: 3px 10px;
    margin-left: 6px;
    border: 1px solid $colorBlue01;
    background-color:#FFF;
    border-radius: 3px;
    color: $colorBlue01;
    font-size: 12px;
    font-size: 1.2rem;
    line-height: 1;
    text-align: center;
    text-decoration: none;
    content: "NEW";
  }
}

/* ico-pdf
---------------------------------------------------------- */
.ico-pdf {
  &:after {
    position: relative;
    display: inline-block;
    width: 17px;
    height: 20px;
    margin-left: 6px;
    background: url(/inc/image/ico_pdf_01.png) no-repeat;
    background-size: 17px 20px;
    content: "";
  }
}

/* ico-blank
---------------------------------------------------------- */
.ico-blank {
  &:after {
    content: "";
    position: relative;
    display: inline-block;
    width: 16px;
    height: 14px;
    margin-left: 6px;
    background: url(/inc/image/top/ico_blank_01.png) no-repeat;
    background-size: 16px 14px;
    vertical-align: top;
  }
}
