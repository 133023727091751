
/* pagetop
---------------------------------------------------------- */
.btn-cmn-pagetop {
  position: fixed;
  right: 10px;
  bottom: 10px;
  z-index: -1;
  opacity: 0;
  transition: opacity 0.2s ease-in;

  &.is-visible {
    z-index: 4;
    opacity: 1;
  }
  &.is-stoped {
    position: absolute;
    top: -42px;
    right: 10px;
  }

  .button {
    display: block;
    width: 32px;
    height: 32px;
    background: url(/inc/image/btn_pagetop.png) no-repeat;
    background-size: 32px 32px;
    @include hoverOpacity();
  }
}
