/* ==========================================================
 list
========================================================== */
/* list-bullet-01
---------------------------------------------------------- */
.list-bullet-01, .list-bullet-02 {
  >li {
    position: relative;
    margin-bottom: 5px;
    padding-left: 20px;
    &:last-child {
      margin-bottom: 0;
    }
    &:before {
      position: absolute;
      top: 9px;
      left: 0;
      width: 7px;
      height: 7px;
      background: $colorBlue01;
      border-radius: 30px;
      content: "";
    }

    @media screen and (max-width: 768px) {
      padding-left: 15px;
      &:before {
        top: 8px;
        width: 6px;
        height: 6px;
      }
    }
  }
}

/* list-bullet-02
---------------------------------------------------------- */
.list-bullet-02 {
  >li {
    padding-left: 15px;

    &:before {
      top: 9px;
      left: 0;
      width: 6px;
      height: 6px;
      background: #000;
      border-radius: 20px;
    }

   @media screen and (max-width: 768px) {
      padding-left: 10px;
      &:before {
        top: 8px;
        width: 4px;
        height: 4px;
      }
    }
  }
}

/* list-number-01
---------------------------------------------------------- */
.list-number-01 {
  counter-reset: section;
  >li {
    position: relative;
    margin-top: 10px;
    margin-bottom: 10px;
    padding-left: 20px;

    &:last-child {
      margin-bottom: 0;
    }
    &:before {
      position: absolute;
      top: 0;
      left: 0;
      color: $colorBlue01;
      counter-increment: section;
      content: counter(section)".";
    }
  }
  h4{
    font-weight: bold;
  }
}

/* list-note-01
---------------------------------------------------------- */
.list-note-01 {
  >li {
    position: relative;
    margin-bottom: 8px;
    padding-left: 18px;
    font-size: 13px;
    font-size: 1.3rem;

    &:before {
      position: absolute;
      top: 0;
      left: 0;
      color: $colorBlue01;
      content: "※";
    }

    @media screen and (max-width: 768px) {
      font-size: 12px;
      font-size: 1.2rem;
    }
  }
}

/* list-card-links
---------------------------------------------------------- */
.list-card-links {
  // overflow: hidden;
  @extend .clearfix;

  @media screen and (min-width: 769px){

    &.type-3-col {
      li {
        width: 33.33%;
        &:nth-child(3n+4){
          clear: left;
        }
        &:nth-child(3n+3){
          .detail {
            // border-right: 1px dotted #b2b2b2;
          }
        }
      }
    }
    // /.type-3-col

    &.type-4-col {
      li {
        width: 25%;
        &:nth-child(4n+1){
          clear: left;
          .detail {
            border-left: none;
          }
        }

        .title {
          margin-top: -5px;
          @media screen and (max-width: 768px){
            margin-top: 0;
          }
        }
      }
    }
    // /.type-4-col

    &.type-5-col {
      li {
        width: 20%;

        &:nth-child(5n+1){
          clear: left;
          .detail {
            border-left: none;
          }
        }

        a{
          &:hover{
            background-color: #fff;
          }
        }

        h4 {
          font-size: 16px;
          font-size: 1.1em;
          text-align: center;
        }
      }
      @media screen and (max-width: 768px){
        margin-top: 0;
      }
    }
    // /.type-5-col

  }

  @media screen and (max-width: 768px){
    margin-bottom: 10px;
  }

  li {
    float: left;
    padding-left: 1px;
    margin: 20px 0;
    box-shadow: 0 0 0 0 rgba(0,0,0,.2);
    transition: box-shadow $hoverAnimationSpeed;

    &:hover{
      position: relative;
      z-index: 10;
      box-shadow: 0 0 25px 0 rgba(0,0,0,.2);
      .title{
        color: $AnchorHoverTextColor;
      }
      .detail{
        border-left-color: #fff;
      }
    }

    @media screen and (max-width: 768px){
      width: 50%;
      &:nth-child(2n+3){
        clear: left;
      }
    }
  }

  figure.image {
    overflow: hidden;
    img {
      width: 100%;
      transition: transform $hoverAnimationSpeed ease-in-out;
    }
  }

  .box-card {
    display: block;
    @media screen and (min-width: 769px){
      &:hover img {
        transform: scale($imgAnimationAfterScale);
      }
    }
  }

  .title {
    margin-bottom: 16px;
    font-size: 1.2em;
    font-weight: 500;
    &:before{
      top: 10px;
    }
    @media screen and (max-width: 768px){
      margin-bottom: 10px;
      font-size: 13px;
      font-size: 1.5rem;
      &:before {
        top: 6px;
        width: 8px;
        height: 8px;
      }

    }
  }

  .detail {
    padding: 10px;
    // border-left: 1px dotted #b2b2b2;
    // text-align: justify;
    @media screen and (max-width: 768px){
      padding-left: 0;
      border-left: 0;
    }
  }

  .text {
    font-size: 14px;
    font-size: 1.4rem;
    line-height: 1.7;
    @media screen and (max-width: 768px){
      font-size: 12px;
      line-height: 1.3;
    }
  }
}
