

/* ==========================================================
 box
========================================================== */
/* box-cmn-01
---------------------------------------------------------- */
.box-cmn-01 {
  a.box-item{
    &:hover{
      background-color: #fff;
      border: 1px solid #cdcdcd;
    }
  }
  .box-item  {
    overflow: hidden;
    display: block;
    padding: 15px;
    transition: background-color .3s;
    background-color: #f3f3f3;
    border: 1px solid #f3f3f3;
    margin-bottom:2px;

    &:first-child {
    }

    .image {
      float: left;
      width: 190px;
      img {
        width: 100%;
      }

      @media screen and (max-width: 768px) {
        float: none;
        width: 100%;
      }
    }

    .box-detail {
      overflow: hidden;
      padding-left: 15px;
      // line-height: 1.7;
      .title {
        margin-bottom: 8px;
        color: $colorBlue01;
        font-size: 18px;
        font-size: 1.8rem;
        line-height: 1.3em;

        @media screen and (max-width: 768px) {
          font-size: 15px;
          font-size: 1.5rem;
        }
      }
    }
  }

  &.txt-center {
    .box-item  {
      .image {
        display: table-cell;
        float: none;
        vertical-align: top;

        @media screen and (max-width: 768px) {
          display: block;
        }
      }
      .box-detail {
        display: table-cell;
        vertical-align: middle;

        @media screen and (max-width: 768px) {
          display: block;
          padding: 10px 0 0;
        }
      }
    }
  }

  @media screen and (min-width: 769px) {
    a.box-item:hover .image {
      // opacity: 0.7;
    }
  }
}

/* box-cmn-02
---------------------------------------------------------- */
.box-cmn-02 {
  padding: 20px;
  background: #f2f2f2;
  line-height: 2;

  .title {
    font-weight: 700;
    font-size: 15px;
    font-size: 1.5rem;
    color: $colorBlue01;
    line-height: 1.7;

    @media screen and (max-width: 768px) {
      font-size: 13px;
      font-size: 1.3rem;
    }
  }
  p {
    &:not(:last-child){
      margin-bottom: 20px;
    }
  }
}

/* box-text-img-01
---------------------------------------------------------- */
.box-text-img-01 {
  overflow: hidden;
  padding: 20px;
  border: 1px solid #cdcdcd;
  .image {
    float: right;
    width: 157px;
    img {
      width: 100%;
    }

    @media screen and (max-width: 768px) {
      float: none;
      width: 100%;
      margin-bottom: 15px;
    }
  }
  .box-detail {
    overflow: hidden;
    padding-right: 20px;

    @media screen and (max-width: 768px) {
      padding-right: 0;
    }
  }
}

/* box-text-img-02
---------------------------------------------------------- */
.box-text-img-02 {
  overflow: hidden;

  @media screen and (max-width: 768px) {
    display: flex;
    flex-wrap: wrap;
  }

  .image {
    float: right;
    width: 240px;
    img {
      width: 100%;
    }

    @media screen and (max-width: 768px) {
      float: none;
      width: 100%;
      order: 1;
    }
  }
  .box-text {
    overflow: hidden;
    padding-right: 20px;

    @media screen and (max-width: 768px) {
      margin-bottom: 10px;
      padding-right: 0;
      order: 0;
    }
  }
}

/* box-hero-text
---------------------------------------------------------- */
.box-hero-text {
  position: relative;
  display: table;
  width: 100%;
  padding: 1px;
  border: 1px solid $colorBlue01;
  border-radius: 4px;

  .box-hero-text-in {
    display: table-cell;
    width: 100%;
    height: 170px;
    padding: 20px;
    border: 1px solid $colorBlue01;
    border-radius: 4px;
    font-family: serif;
    font-size: 24px;
    font-size: 2.4rem;
    text-align: center;
    vertical-align: middle;

    @media screen and (max-width: 768px) {
      height: 120px;
      padding: 20px 15px;
      font-size: 18px;
      font-size: 1.8rem;
    }
  }

  &.txt-small {
    .box-hero-text-in {
      font-size: 17px;
      font-size: 1.7rem;

      @media screen and (max-width: 768px) {
        font-size: 15px;
        font-size: 1.5rem;
      }
    }
  }
}

/* box-table-layout-01
---------------------------------------------------------- */
.box-table-layout-01 {
  border-top: 1px solid #cdcdcd;
  width: 100%;
  .box-item {
    display: table;
    width: 100%;

    &:nth-child(even) {
      background-color: #fbfbfb;
    }

    @media screen and (max-width: 768px) {
      table-layout: fixed;
    }
  }
  .box-head {
    display: table-cell;
    width: 160px;
    padding: 15px;
    background: #f5f5f5;
    border-bottom: 1px solid #cdcdcd;
    font-weight: 700;
    text-align: center;

    @media screen and (max-width: 768px) {
      width: 120px;
    }
  }
  .box-detail {
    display: table-cell;
    border-bottom: 1px solid #cdcdcd;
    padding: 15px;
    text-align: left;
    vertical-align: top;
    @media screen and (max-width: 768px) {
      padding: 8px;
    }
  }
}

/* box-table-wrapper
---------------------------------------------------------- */
@media screen and (max-width: 768px) {
  .box-table-wrapper {
    overflow: auto;
  }
  .scrollable-table-wrapper {
    margin-top: 25px;
    overflow: auto;
    border: 2px solid #eeee01;
      &::before {
        content: "横にスクロールができます ◀︎▶︎";
        display: inline-block;
        position: absolute;
        height: 25px;
        line-height: 25px;
        background-color: rgb(238, 238, 0);
        padding: 0px 5px;
        border-radius: 3px 3px 0px 0px;
        color: #314f8c;
        margin-top: -25px;
      }
  }
}

/* box-news-highlight
---------------------------------------------------------- */
.box-news-highlight {
  display: table;
  width: 100%;
  margin-bottom: 30px;
  background: #fff54a;
  text-align: center;
  border-radius: 5px;

  @media screen and (max-width: 768px) {
    display: flex;
    flex-wrap: wrap;
  }

  .box-news-txt-wrap {
    width: 100%;
  }

  .txt-title {
    display: table-cell;
    width: 143px;
    padding: 10px 0;
    background: #ffe800;
    border-radius: 5px 0 0 5px;
    vertical-align: middle;

    @media screen and (max-width: 768px) {
      display: block;
      width: 100%;
      padding: 6px 0;
    }
  }
  .txt-date {
    display: table-cell;
    width: 110px;
    padding: 10px 0;

    @media screen and (max-width: 768px) {
      display: block;
      width: 100%;
      padding: 10px 15px 0;
      line-height: 1;
      text-align: left;
    }
  }
  .txt-detail {
    display: table-cell;
    padding: 10px 0;
    text-align: left;
    a:hover {
      text-decoration: underline;
    }

    @media screen and (max-width: 768px) {
      display: block;
      width: 100%;
      padding: 0 15px 5px;
    }
  }
}

/* box-history-01
---------------------------------------------------------- */
.box-history-01 {
  position: relative;
  padding-bottom: 50px;

  @media screen and (max-width: 768px) {
    padding-bottom: 20px;
  }

  &:before {
    position: absolute;
    top: 30px;
    left: 160px;
    bottom: 0;
    width: 1px;
    background-color: #d2d2e7;
    content: "";

    @media screen and (max-width: 768px) {
      top: 15px;
      left: 92px;
    }
  }

  >.box-item {
    display: table;
    width: 100%;

    &:nth-child(even) {
      background-color: #f7f7f7;
    }

    .box-date {
      display: table-cell;
      padding: 20px 0;
      width: 160px;
      text-align: center;
      vertical-align: top;

      @media screen and (max-width: 768px) {
        padding: 10px 0;
        width: 90px;
      }

      .ttl-main {
        font-size: 20px;
        font-size: 2rem;
        font-weight: 700;
        line-height: 1.1;

        @media screen and (max-width: 768px) {
          font-size: 16px;
          font-size: 1.6rem;
        }
      }
      .ttl-sub {
        font-size: 14px;
        font-size: 1.4rem;

        @media screen and (max-width: 768px) {
          font-size: 12px;
          font-size: 1.2rem;
        }
      }
    }
    .box-info {
      position: relative;
      display: table-cell;
      padding: 18px 0 20px 45px;
      vertical-align: top;

      @media screen and (max-width: 768px) {
        padding: 7px 0 10px 25px;
      }

      &:before {
        position: absolute;
        top: 25px;
        left: -4px;
        width: 10px;
        height: 10px;
        background: $colorBlue01;
        border-radius: 10px;
        content: "";

        @media screen and (max-width: 768px) {
          top: 14px;
          left: 0;
          width: 6px;
          height: 6px;
        }
      }
    }
  }
}

/* box-accordion-01
---------------------------------------------------------- */
.box-accordion-01 {
  >.box-item {
    .box-question {
      position: relative;
      padding: 16px 15px 16px 48px;
      background-color: #f2f2f2;
      cursor: pointer;
      @include hoverOpacity();

      &:before, &.is-open:before {
        position: absolute;
        top: 15px;
        left: 12px;
        width: 25px;
        height: 25px;
        background: url(/inc/image/ico_plus.png) no-repeat;
        background-size: 25px 25px;
        content: "";
      }

      &.is-open {
        background: $colorBlue01 !important;
        color: #fff;
      }
      &.is-open:before {
        background: url(/inc/image/ico_minus.png) no-repeat;
        background-size: 25px 25px;
      }
    }
    &:nth-child(even) .box-question {
      background-color: #fff;
    }

    .box-answer {
      display: none;
      padding: 15px 20px 25px;
      background-color: #f0f5fb;
      line-height: 2;
    }
  }
}
