/* ==========================================================
 * Fonts
 *==========================================================*/
 @font-face {
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 100;
  src: url(/inc/fonts/NotoSans-Thin.woff2) format('woff2'), url(/inc/fonts/NotoSans-Thin.woff) format('woff'), url(/inc/fonts/NotoSans-Thin.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 200;
  src: url(/inc/fonts/NotoSans-ExtraLight.woff2) format('woff2'), url(/inc/fonts/NotoSans-ExtraLight.woff) format('woff'), url(/inc/fonts/NotoSans-ExtraLight.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 300;
  src: url(/inc/fonts/NotoSans-Light.woff2) format('woff2'), url(/inc/fonts/NotoSans-Light.woff) format('woff'), url(/inc/fonts/NotoSans-Light.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 400;
  src: url(/inc/fonts/NotoSans-Regular.woff2) format('woff2'), url(/inc/fonts/NotoSans-Regular.woff) format('woff'), url(/inc/fonts/NotoSans-Regular.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Sans';
  font-style: bold;
  font-weight: 500;
  src: url(/inc/fonts/NotoSans-Medium.woff2) format('woff2'), url(/inc/fonts/NotoSans-Medium.woff) format('woff'), url(/inc/fonts/NotoSans-Medium.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Sans';
  font-style: bolder;
  font-weight: 700;
  src: url(/inc/fonts/NotoSans-Bold.woff2) format('woff2'), url(/inc/fonts/NotoSans-Bold.woff) format('woff'), url(/inc/fonts/NotoSans-Bold.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Sans';
  font-style: bolder;
  font-weight: 800;
  src: url(/inc/fonts/NotoSans-ExtraBold.woff2) format('woff2'), url(/inc/fonts/ExtraBold.woff) format('woff'), url(/inc/fonts/ExtraBold.ttf) format('truetype');
}
@font-face {
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 900;
  src: url(/inc/fonts/NotoSans-Black.woff2) format('woff2'), url(/inc/fonts/NotoSans-Black.woff) format('woff'), url(/inc/fonts/NotoSans-Black.ttf) format('truetype');
}
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: url(/inc/fonts/Roboto-Regular.ttf) format('truetype');
}
