
/* ==========================================================
 card
========================================================== */
/* card-cmn-01
---------------------------------------------------------- */
.card-cmn-01, .card-cmn-02 {
  text-align: center;
  a{
    transition: box-shadow $hoverAnimationSpeed;
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    &:hover{
      box-shadow: $hoverAnimationShadow;
      position: relative;
      z-index: 10;
    }
  }
  .image {
    overflow: hidden;
    display: block;
    img {
      width: 100%;
      // max-height: 200px;
      transition: transform 0.2s ease-in;
    }

    @media screen and (min-width: 769px) {
      img {
        width: 100%;
      }
      &:hover img {
        transform: scale($imgAnimationAfterScale);
      }
    }
  }
  .title {
    margin: 10px 0;
    font-weight: 500;

    @media screen and (max-width: 768px) {
      font-size: 14px;
      font-size: 1.4rem;
    }
  }

  .txt-code{
    color: lighten($baseFontColor,9);
    font-size: 13px;
    font-size: 1.3rem;
    margin-top: -10px;
  }

  .txt-info {
    font-size: 13px;
    font-size: 1.3rem;

    @media screen and (max-width: 768px) {
      font-size: 11px;
      font-size: 1.1rem;
    }
  }
}

/* card-cmn-02
---------------------------------------------------------- */
.card-cmn-02 {
  .title {
    margin-bottom: 0;
    color: $colorBlue01;
  }
  .txt-info {
    font-size: 14px;
    font-size: 1.4rem;
    text-align: left;

    @media screen and (max-width: 768px) {
      font-size: 12px;
      font-size: 1.2rem;
    }
  }
}
