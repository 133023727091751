/* ==========================================================
 table
========================================================== */
/* table-cmn-01
---------------------------------------------------------- */

//.article-product .tabは製品詳細内のテーブル用
//.box-news-detail-content tableはニュース詳細用
.table-cmn-01,
.article-product .tab table,
.box-news-detail-content table{
  width: 100%;

  @media screen and (max-width: 768px) {
    &.full {
      width: 100%;
    }
  }

  th {
    padding: 11px 15px;
    background: #f5f5f5;
    border-right: 1px dashed #c4c4c4;
    border-bottom: 1px solid #cdcdcd;
    font-weight: 700;
    text-align: center;
    &:last-child {
      border-right: none;
    }
  }
  td {
    padding: 11px 15px;  
    border-right: 1px dashed #c4c4c4;
    border-bottom: 1px solid #cdcdcd;
    &:last-child {
      border-right: none;
    }
  }
  tr.color-01 {
    background: #ffffcb;
    color: #c14900;
  }
}

//[横にスクロールができます◀▶]のテーブル
table {
  & .scrollable-table-wrapper {
    margin-top: 25px;
  }
  &.no_border{
  	td{
  		border: none;
  	}
  }
}
