/* ==========================================================
 text
========================================================== */
/* txt-cmn-01
---------------------------------------------------------- */
.txt-cmn-01 {
  margin-bottom: 20px;
  line-height: 2;

  @media screen and (max-width: 768px) {
    margin-bottom: 10px;
  }

  &:last-of-type {
    margin-bottom: 0;
  }
}

/* txt-highlight-01
---------------------------------------------------------- */
.txt-highlight-01 {
  color: $colorBlue01;
  font-weight: bold;
}
@media screen and (min-width: 769px) {
  a.txt-highlight-01:hover {
    text-decoration: underline;
  }
}

/* txt-remark-01, txt-remark-02
---------------------------------------------------------- */
.txt-remark-01, .txt-remark-02 {
  font-size: 13px;
  font-size: 1.3rem;

  @media screen and (max-width: 768px) {
    font-size: 11px;
    font-size: 1.1rem;
  }
}

.txt-remark-02 { color: #ef1616; }

/* txt-link-arrow-01
---------------------------------------------------------- */
.txt-link-arrow-01 {
  position: relative;
  display: inline-block;
  padding-left: 20px;

  &.white {
    color: #fff;
  }

  &:before {
    position: absolute;
    top: 50%;
    left: 0;
    width: 14px;
    height: 14px;
    margin-top: -7px;
    background: url(/inc/image/ico_link_arrow_01_off.png) no-repeat;
    background-size: 14px 14px;
    content: "";
  }

  @media screen and (min-width: 769px) {
    &:hover {
      color: $colorBlue01;
      text-decoration: underline;
    }
    &.white:hover {
      color: #fff100;
    }
  }

  /*@media screen and (max-width: 768px) {
    &:before { top: 3px; }
  }*/

  &.txt-large {
    font-weight: 500;
    font-size: 18px;
    font-size: 1.8rem;

     /*&:before {
      top: 9px;
     }*/

     @media screen and (max-width: 768px) {
      font-size: 16px;
      font-size: 1.6rem;
    }
  }
}
