%mm-btn {
  border-radius: 4px;
  background-color: #fff;
  transition: background-color $hoverAnimationSpeed, box-shadow $hoverAnimationSpeed;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  &:hover {
    background-color: rgba(255, 255, 255, 0.9);
    box-shadow: $hoverAnimationShadow;
  }
}

/* ==========================================================
 nav-global
========================================================== */
.nav-global {
  position: absolute;
  top: 27px;
  left: 0;
  width: 100%;
  transition: top $headerFixedAnimationSpeed;
  @media screen and (min-width: 769px){
  }
  @media screen and (max-width: 768px){
    display: none;
  }
}
.list-nav-global {
  @media screen and (min-width: 769px){
    width: 1200px;
    margin: 0 auto;
    padding-left: 185px;
    overflow: hidden;
  }
  @media screen and (max-width: 768px){
  }
  .item {
    @media screen and (min-width: 769px){
      float: left;
      height: 40px;
      line-height: 1;
      margin-left: 1px;
      border-radius: 3px 3px 0 0;
      transition: height .2s;
      &.nav01 {
        padding-top: 8px;
        margin-right: 10px;
      }
      &.is-active {
        background-color: $colorBlue01;
        height: 52px;
        > a {
          color: #ffff00;
        }
        .ico-blank:after{
          background-image: url(/inc/image/top/ico_blank_02.png);
        }
      }
    }
    @media screen and (max-width: 768px) {
    }
    > a {
      @media screen and (min-width: 769px){
        display: inline-block;
        padding: 8px 15px;
        border-radius: 3px;
        font-size: 15px;
        font-size: 1.5rem;
        font-weight: 500;
        line-height: 1.1;
        color: $colorBlue01;
        transition: background-color .2s;
        &.home {
          width: 18px;
          padding: 0;
          background: url(/inc/image/ico_home_01.png) 0 0 no-repeat;
          background-size: 100% auto;
          overflow: hidden;
          text-indent: -9999px;
          @include hoverOpacity;
        }
        &:hover, &.is-active {
          &:not(.home){
            background-color: $colorBlue01;
            color: #ffff00;
          }

          &.ico-blank:after{
            background-image: url(/inc/image/top/ico_blank_02.png);
          }
        }
      }
      @media screen and (max-width: 768px){
      }
    }
  }
}

.mod-mega-menu {
  display: none;
  background-color: $colorBlue01;

  @media screen and (min-width: 769px){
    position: absolute;
    top: 52px;
    left: 0;
    width: 100%;
    z-index: 99;
    background-size: auto 100%;
    background-position: right center;
    background-repeat: no-repeat;
    &.mega-menu-01 {
      background-image: url(/inc/image/bg_megamenu_01.jpg);
    }
    &.mega-menu-02 {
      background-image: url(/inc/image/bg_megamenu_02.jpg);
      .box-inner{
        padding-right: 320px;
      }
    }
    &.mega-menu-03 {
      background-image: url(/inc/image/bg_megamenu_03.jpg);
    }
    &.mega-menu-04 {
      background-image: url(/inc/image/bg_megamenu_04.jpg);
    }
  }

  .box-inner {
    color: #fff;
    overflow: hidden;
    @media screen and (min-width: 769px){
      width: 1200px;
      height: 280px;
      margin: 0 auto;
      padding: 20px;
    }
  }

  .grid-megamenu-nav-01 {
    overflow: hidden;
    @media screen and (min-width: 769px){
      height: 100%;
      // margin-left: -30px;
    }
    @media screen and (max-width: 768px){
    }
    .col {
      @media screen and (min-width: 769px){
        float: left;
        width: 200px;
        height: 100%;
        margin-right: 10px;
      }
    }
  }
  .list-cate-01 {
    > li {
      &:not(:last-child) {
        margin-bottom: 7px;
        .list-links {
          @media screen and (min-width: 769px){
          }
        }
      }
    }
  }

  .link-cate-01 {
    &:not(:last-child) {
      .list-links {
        @media screen and (min-width: 769px){
          margin-bottom: 11px;
        }
      }
    }

    &.hasChild{
      > a{
        border-radius: 5px 5px 0 0;
      }
    }

    @media screen and (min-width: 769px){
      position: relative;
      overflow: hidden;
      &.full-height {
        height: 100%;
      }
    }

    @media screen and (max-width: 768px){
    }

    > a {
      display: block;
      line-height: 1;
      @extend %mm-btn;

      &.is-active{
        color: $colorYellow01;
        background-color: rgba(255, 255, 255, 0.2)
      }

      @media screen and (min-width: 769px){
        padding: 19px 5px;
        font-size: 15px;
        font-size: 1.5rem;
        font-weight: 500;
        color: $colorBlue01;
        text-align: center;
      }
    }
    .list-links {
      margin-top: 5px;
    }
  }


  .list-links {
    li {
      padding: 6px 10px;
      transition: background-color $hoverAnimationSpeed;

      &:not(:last-child) {
        margin-bottom: 3px;
      }

      a, span {
        display: inline-block;
        @media screen and (min-width: 769px){
          font-size: 13px;
          font-size: 1.3rem;
          line-height: 1.3;
          color: #fff;
          &:not(span):hover {
            color: $colorYellow01;
            text-decoration: underline;
          }
        }
      }

      &:hover{
        border-radius: 5px;
        background-color: rgba(255,255,255,.1);
      }
    }
  }
  .grid-megamenu-nav-02 {
    @media screen and (min-width: 769px){
      overflow: hidden;
      .col {
        width: auto;
        &:last-child {
        }
      }
    }
    @media screen and (max-width: 768px){
    }
  }
  .list-cate-02 {
    overflow: hidden;

    li {
      margin: 0 3px 3px 0;
      @media screen and (min-width: 769px){
        float: left;
        width: 24%;
      }
    }
    a {
      position: relative;
      display: table;
      width: 100%;
      line-height: 1;
      @extend %mm-btn;
      @media screen and (min-width: 769px){
        height: 80px;
        background-color: #fff;
        font-weight: 500;
        color: $colorBlue01;
      }
      &.is-active{
        background-color: $colorYellow01;
        border: 1px solid $colorYellow01;
      }
    }
    .image {
      position: absolute;
      top: 50%;
      left: 0;
      width: 80px;
      height: 100%;
      transform: translateY(-50%);
      @media screen and (min-width: 769px){
      }
      @media screen and (max-width: 768px){
      }
      img {
        height: 100%;
        border-radius: 5px 0 0 5px;
      }
    }
    .text {
      display: table-cell;
      vertical-align: middle;
      padding-left: 100px;
    }
  }
  .list-cate-03 {
    @media screen and (min-width: 769px){
      width: 830px;
      overflow: hidden;
    }
    li {
      @media screen and (min-width: 769px){
        float: left;
        margin: 1px;
        width: 33%;
      }
      @media screen and (max-width: 768px){
      }
    }
    a {
      display: block;
      line-height: 1;
      @extend %mm-btn;
      @media screen and (min-width: 769px){
        height: 75px;
        border-radius: 4px;
        padding-top: 32px;
        font-size: 15px;
        font-size: 1.5rem;
        font-weight: 500;
        color: $colorBlue01;
        text-align: center;

      }
    }
  }
  .grid-megamenu-nav-04 {
    @media screen and (min-width: 769px){
      height: 100%;
      overflow: hidden;
      .col {
        float: left;
        height: 100%;
        &:first-child {
          width: 425px;
          margin-right: 20px;
        }
        &:last-child {
          width: 210px;
        }
      }
    }
    @media screen and (max-width: 768px){
    }
  }
  .list-cate-04 {
    @media screen and (min-width: 769px){
      height: 100%;
      overflow: hidden;
    }
    li {
      @media screen and (min-width: 769px){
        float: left;
        width: 50%;
        height: 50%;
        border-right: 2px solid $colorBlue01;
        border-bottom: 2px solid $colorBlue01;
      }
      @media screen and (max-width: 768px){
      }
    }
    a {
      display: block;
      line-height: 1;
      @media screen and (min-width: 769px){
        height: 100%;
        padding-top: 52px;
        background-color: #fff;
        border: 1px solid #fff;
        font-size: 15px;
        font-size: 1.5rem;
        font-weight: 500;
        color: $colorBlue01;
        text-align: center;
        &:hover {
          background-color: rgba(255, 255, 255, 0.2);
          color: $colorWhite;
        }
      }
    }
  }
}

@for $i from 1 through 5 {
  #g0#{$i} {

    // PC用メニューアクティブ
    .list-nav-global {

      .item {
        &:nth-child(#{$i+1}) {
          > a {
            background-color: $colorBlue01;
            color: #ffff00;
          }
        }
      }

    }

    //スマホ用メニューアクティブ
    .nav-global-sp{
      .list-nav-global {
        >li:nth-child(#{$i+1}){
          > a {
            color: #ffff00;
            // background-color: #03152b;
            &.has-submenu:after {
              transform: translateY(-50%) rotate(-90deg);
            }
          }
          >div{
            border-top: none;
            display: block;
          }
        }
      }
    }
  }
}


/* ==========================================================
 nav-global-sp
========================================================== */
.nav-global-sp-wrapper {
  display: none;
}

@media screen and (max-width: 768px){
  .nav-global-sp-wrapper {
    position: fixed;
    top: 0;
    left: 57px;
    right: 0;
    bottom: 0;
    z-index: 10;
    display: block;
    background-color: #042142;
    transition: transform 0.2s ease-in;
    transform: translateX(100%);
    backface-visibility: hidden;
    //サイト内検索
    form{
      margin: 10px;
      input{
        padding: 5px;
        border-radius: 4px;
        width: 100%;
      }
    }
    .nav-global-sp {
      overflow: auto;
      height: 100%;

      .btn-home {
        height: 44px;
        background: url(/inc/image/ico_home_sp.png) center center no-repeat;
        background-size: 14px 11px;
      }

      .ico-blank {
        display: inline-block;
        padding-right: 18px;
        background: url(/inc/image/ico_new_window_02.png) right 3px no-repeat;
        background-size: 12px 12px;
        //Note: なぜかグローバルメニューは二重にアイコンがつくので
        &:after{
          content: none;
          background: none;
        }
      }
    }

    .list-nav-global {
      >li {
        border-bottom: 1px solid #020f1d;

        >a {
          position: relative;
          display: block;
          color: #fff;
          padding: 16px 35px;
          font-size: 14px;
          font-size: 1.4rem;
          line-height: 1.4;
          text-align: center;
          text-decoration: none;

          &.has-submenu:after {
            position: absolute;
            top: 50%;
            right: 25px;
            width: 6px;
            height: 9px;
            background: url(/inc/image/ico_arrow_left_02.png) center center no-repeat;
            background-size: 6px 9px;
            transform: translateY(-50%);
            content: "";
          }
          &.has-submenu.is-open:after {
            transform: translateY(-50%) rotate(-90deg);
          }
        }
      }
    }

    .box-nav-sub {
      display: none;
      background-color: #03152b;

      .list-nav-sub {
        li {
          a {
            position: relative;
            display: block;
            padding: 14px 20px 14px 40px;
            color: #fff;
            font-size: 14px;
            font-size: 1.4rem;
            line-height: 1.4;

            &:before {
              position: absolute;
              top: 50%;
              left: 24px;
              width: 7px;
              height: 1px;
              margin-top: -1px;
              background-color: #ffff00;
              content: "";
            }

            span {
              display: inline-block;
              text-align: center;
            }

            &.is-active{
              color: $colorYellow01;
            }
          }
        }
      }
    }
  }
}


/* ==========================================================
 nav-local
========================================================== */
/* nav-local-01
---------------------------------------------------------- */

$submenu_bgColor: #003399;

.nav-local-01 {
  z-index: 999;
  left: 0;
  top: 0;
  width: 100%;
  border-top: 1px dotted #ccc;
  border-bottom: 1px dotted #ccc;
  position: relative;

  .heading {
    display: none;
  }

  .inner-wrapper-sticky{
    transition: all .5s;
  }

  //追従時
  &.is-affixed{
    border: none;
    .inner-wrapper-sticky{
      background-color: #f2f2f2;
      width: 100%!important;
      left: 0!important;
      box-shadow:0px 4px 15px 0px rgba(0,0,0,0.16);
      >ul{
        margin: 0 auto;
      }
    }
  }

   ul {
    font-size: .9em;
    width: 1000px;
    margin: 20px auto;
    text-align: center;
    letter-spacing: -0.3em;
    transition: all .5s;

    > li {
      letter-spacing: normal;
      display: inline-block;
      padding-left: 2px;
      padding-right: 2px;
      &:after{
        content:"";
        display: inline-block;
        border-right: 1px solid #ccc;
        height: 1em;
      }
    }
    > li:last-child{
      &:after{
        display: none;
      }
    }

    > li > a {
      padding: 10px;
      position: relative;
      white-space: nowrap;
      display: inline-block;
      line-height: 1em;
      font-weight: 500;
      color: #222;
      transition: background-color .3s;
      border-radius: 4px;
      &:hover {
        background-color: #f6f6f6;
        .fixedSubNavi &{
          border-radius: 0;
          background-color: #e6e6e6;
        }
      }
      &.is-active{
        font-weight: 600;
        color: $colorBlue01;
        &:after{
          filter: grayscale(0%) brightness(100%)!important;
        }
      }
    }
  }
}
@for $i from 1 through 20 {
  .l0#{$i} {
    .nav-local-01 {
      > ul > li:nth-child(#{$i}) {
        > a {
          color: $colorBlue01;
          &:before {
            display: block;
          }
        }
      }
    }
  }
}

/* nav-local-sub

---------------------------------------------------------- */

.nav-local-01{

  ul > li > a.has-sub {
    position: relative;
    padding-right: 30px;
    border-radius: 3px;

    &:after {
      content: "";
      display: block;
      position: absolute;
      top: 50%;
      right: 9px;
      z-index: 1;
      width: 14px;
      height: 14px;
      background: url(/inc/image/ico_link_arrow_01_off.png) no-repeat;
      background-size: 14px 14px;
      transform: translateY(-50%) rotate(-180deg);
      filter: grayscale(100%) brightness(200%);
    }

    &.is-open {
      box-shadow: 0px 2px 10px -2px rgba(0, 0, 0, 0.129);
      background-color: $submenu_bgColor;
      border-radius: 4px 4px 0 0;
      color: $colorYellow01;
      &:after {
        transform: translateY(-50%) rotate(-270deg);
        filter: grayscale(0%) brightness(100%);
      }
    }
  }

  .nav-local-sub {
    display: none;
    position: absolute;
    z-index: 100;
    background-color: $submenu_bgColor;
    text-align: left;
    border-radius: 0 4px 4px 4px ;
    box-shadow: 0px 5px 10px -2px rgba(0, 0, 0, 0.129);
    ul{
      width: auto;
      margin-top: 10px;
      margin-bottom: 10px;
      li {
        display: block;
        text-align: left;
        &:after {
          display: none;
        }
        a {
          color: #fff;
          position: relative;
          display: block;
          padding: 7px 25px;
          transition: background-color .3s;

          &:hover, &.is-active {
            background-color: lighten($submenu_bgColor,4);
            color: $colorYellow01;
          }

        }
      }
    }
  }
}


/* ==========================================================
 nav-breadcrumb
========================================================== */
.nav-breadcrumb {
  overflow: hidden;
  padding: 10px 0;
  font-size: 11px;
  font-size: 1.2rem;
  font-weight: 100;

  @media screen and (max-width: 768px){
    font-size: 12px;
    font-size: 1.2rem;
  }

  ul {
    li {
      float: left;
      &:after {
        padding: 0 7px;
        content: "/";
      }
      &:last-child:after {
        display: none;
      }
      a:hover {
        text-decoration: underline;
      }
    }

    @media screen and (max-width: 768px){
      overflow: auto;
      width: 100%;
      white-space: nowrap;

      li {
        float: none;
        display: inline-block;
      }
    }
  }
}
