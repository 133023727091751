@charset "UTF-8";
/* ==========================================================
 * Variables
 *==========================================================*/
/* ==========================================================
 * Mixin
 *==========================================================*/
@import url(fontawesome/fontawesome.css);
@import url(fontawesome/fa-solid.css);
/* ==========================================================
 reseting
========================================================== */
body, div, pre, p, blockquote, dl, dt, dd, ul, ol, li, h1, h2, h3, h4, h5, h6, form, fieldset, th, td, button, figure, figcaption {
  margin: 0;
  padding: 0;
}

input, textarea {
  margin: 0;
  font-size: 100%;
}

input, textarea, select {
  color: inherit;
  font-size: inherit;
  outline: none;
  /*-webkit-appearance: none;*/
}

textarea {
  resize: none;
}

button {
  border: none;
  overflow: visible;
  background: none;
  vertical-align: top;
  font-size: 100%;
  color: inherit;
  cursor: pointer;
  -webkit-appearance: none;
}

label {
  cursor: pointer;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
  font-size: 100%;
}

fieldset {
  border: 0;
}

img {
  border: 0;
  height: auto;
  vertical-align: top;
}

address, caption, code, em, th {
  font-style: normal;
  font-weight: normal;
}

ol, ul {
  list-style: none;
}

caption, th {
  text-align: left;
}

h1, h2, h3, h4, h5, h6, em {
  font-size: 100%;
  font-weight: normal;
}

a, input {
  /* outline: none; -webkit-tap-highlight-color:rgba(0,0,0,0);*/
}

sup, sub {
  font-size: 63%;
}

sup {
  vertical-align: top;
}

sub {
  vertical-align: baseline;
}

* {
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
}

/* ==========================================================
 grid
========================================================== */
/* grid-list-col2-01
---------------------------------------------------------- */
.grid-list-col2-01 {
  overflow: hidden;
  margin: -30px 0 0 -20px;
}

.grid-list-col2-01 .box-item {
  float: left;
  width: 50%;
  margin-top: 10px;
  margin-bottom: 40px;
}

.grid-list-col2-01 .box-item:not(:first-child) {
  padding-left: 20px;
}

@media screen and (min-width: 769px) {
  .grid-list-col2-01 .box-item:nth-child(2n+1) {
    clear: left;
  }
}

@media screen and (max-width: 768px) {
  .grid-list-col2-01 {
    margin: -20px 0 0;
  }
  .grid-list-col2-01 .box-item {
    width: 100%;
    margin-top: 20px;
    padding-left: 0;
  }
}

/* grid-list-col2-02
---------------------------------------------------------- */
.grid-list-col2-02 {
  overflow: hidden;
  margin: -30px 0 0 -20px;
}

.grid-list-col2-02 .box-item {
  float: left;
  width: 50%;
}

.grid-list-col2-02 .box-item:nth-child(2n+1) {
  clear: left;
}

@media screen and (max-width: 768px) {
  .grid-list-col2-02 {
    margin: -15px 0 0 -10px;
  }
  .grid-list-col2-02 .box-item {
    margin-top: 15px;
    padding-left: 10px;
  }
}

/* grid-list-col3-01
---------------------------------------------------------- */
.grid-list-col3-01 {
  overflow: hidden;
  margin: -30px 0 0 -20px;
}

.grid-list-col3-01 .box-item {
  float: left;
  width: 33.333%;
  margin-top: 30px;
  padding-left: 20px;
}

@media screen and (min-width: 769px) {
  .grid-list-col3-01 .box-item:nth-child(3n+1) {
    clear: left;
  }
}

@media screen and (max-width: 768px) {
  .grid-list-col3-01 {
    margin: -20px 0 0 -10px;
  }
  .grid-list-col3-01 .box-item {
    width: 50%;
    margin-top: 20px;
    padding-left: 10px;
  }
  .grid-list-col3-01 .box-item:nth-child(2n+1) {
    clear: left;
  }
}

/* grid-list-col4-01
---------------------------------------------------------- */
.grid-list-col4-01 {
  margin: 0 !important;
}

.grid-list-col4-01:after {
  content: "";
  display: table;
  clear: both;
}

.grid-list-col4-01 .box-item {
  float: left;
  width: 25%;
  margin-bottom: 25px;
  margin-top: 15px;
}

@media screen and (min-width: 769px) {
  .grid-list-col4-01 .box-item:nth-child(4n+1) {
    clear: left;
  }
}

@media screen and (max-width: 768px) {
  .grid-list-col4-01 {
    margin: -20px 0 0 -10px;
  }
  .grid-list-col4-01 .box-item {
    width: 50%;
    margin-top: 20px;
    padding-left: 10px;
  }
  .grid-list-col4-01 .box-item:nth-child(2n+1) {
    clear: left;
  }
}

/* ==========================================================
 layout
========================================================== */
html {
  overflow-y: scroll;
}

html.is-locked {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
}

/* ==========================================================
 wrapper and container
========================================================== */
.wrapper {
  padding-top: 80px;
  width: 100%;
}

.fixedheader .wrapper {
  adding-top: 55px;
}

@media screen and (min-width: 769px) {
  .wrapper {
    min-width: 1120px;
    overflow: hidden;
  }
}

@media screen and (max-width: 768px) {
  .wrapper {
    padding-top: 55px;
    transition: transform 0.2s ease-in;
  }
}

@media screen and (min-width: 769px) {
  .container {
    min-width: 1000px;
    width: 1000px;
    margin: 0 auto;
  }
}

@media screen and (max-width: 768px) {
  .container {
    padding-right: 10px;
    padding-left: 10px;
  }
}

/* ==========================================================
 contents
========================================================== */
.contents {
  position: relative;
}

@media screen and (min-width: 769px) {
  .contents {
    margin: 0 auto 100px;
  }
}

@media screen and (max-width: 768px) {
  .contents {
    margin-bottom: 50px;
  }
}

.contents-layout-01 {
  margin-bottom: -100px;
}

@media screen and (max-width: 768px) {
  .contents-layout-01 {
    margin-bottom: -50px;
  }
}

@media screen and (min-width: 769px) {
  .contents-layout-01 .contents-main {
    width: 100%;
    clear: both;
    padding-bottom: 100px;
  }
}

@media screen and (max-width: 768px) {
  .contents-layout-01 .contents-main {
    padding-bottom: 50px;
  }
}

@media screen and (min-width: 769px) {
  .contents-layout-01 .contents-sub {
    margin-bottom: 50px;
    width: 100%;
  }
}

@media screen and (max-width: 768px) {
  .contents-layout-01 .contents-sub {
    display: none;
  }
}

.contents-layout-02 {
  overflow: hidden;
}

@media screen and (min-width: 769px) {
  .contents-layout-02 .contents-main {
    float: right;
    width: 710px;
  }
}

@media screen and (min-width: 769px) {
  .contents-layout-02 .contents-sub {
    float: left;
    height: 100vh;
    background: #868b94;
  }
}

@media screen and (max-width: 768px) {
  .contents-layout-02 .contents-sub {
    display: none;
  }
}

/* ==========================================================
 section
========================================================== */
/* sec-cmn
---------------------------------------------------------- */
/* section last margin */
.sec-cmn-01 {
  margin-bottom: 50px;
  position: relative;
}

.sec-cmn-01:last-child {
  margin-bottom: 0;
}

@media screen and (max-width: 768px) {
  .sec-cmn-01 {
    margin-bottom: 20px;
  }
}

section:last-of-type {
  margin-bottom: 0;
}

/* ==========================================================
 * Fonts
 *==========================================================*/
@font-face {
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 100;
  src: url(/inc/fonts/NotoSans-Thin.woff2) format("woff2"), url(/inc/fonts/NotoSans-Thin.woff) format("woff"), url(/inc/fonts/NotoSans-Thin.ttf) format("truetype");
}

@font-face {
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 200;
  src: url(/inc/fonts/NotoSans-ExtraLight.woff2) format("woff2"), url(/inc/fonts/NotoSans-ExtraLight.woff) format("woff"), url(/inc/fonts/NotoSans-ExtraLight.ttf) format("truetype");
}

@font-face {
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 300;
  src: url(/inc/fonts/NotoSans-Light.woff2) format("woff2"), url(/inc/fonts/NotoSans-Light.woff) format("woff"), url(/inc/fonts/NotoSans-Light.ttf) format("truetype");
}

@font-face {
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 400;
  src: url(/inc/fonts/NotoSans-Regular.woff2) format("woff2"), url(/inc/fonts/NotoSans-Regular.woff) format("woff"), url(/inc/fonts/NotoSans-Regular.ttf) format("truetype");
}

@font-face {
  font-family: 'Noto Sans';
  font-style: bold;
  font-weight: 500;
  src: url(/inc/fonts/NotoSans-Medium.woff2) format("woff2"), url(/inc/fonts/NotoSans-Medium.woff) format("woff"), url(/inc/fonts/NotoSans-Medium.ttf) format("truetype");
}

@font-face {
  font-family: 'Noto Sans';
  font-style: bolder;
  font-weight: 700;
  src: url(/inc/fonts/NotoSans-Bold.woff2) format("woff2"), url(/inc/fonts/NotoSans-Bold.woff) format("woff"), url(/inc/fonts/NotoSans-Bold.ttf) format("truetype");
}

@font-face {
  font-family: 'Noto Sans';
  font-style: bolder;
  font-weight: 800;
  src: url(/inc/fonts/NotoSans-ExtraBold.woff2) format("woff2"), url(/inc/fonts/ExtraBold.woff) format("woff"), url(/inc/fonts/ExtraBold.ttf) format("truetype");
}

@font-face {
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 900;
  src: url(/inc/fonts/NotoSans-Black.woff2) format("woff2"), url(/inc/fonts/NotoSans-Black.woff) format("woff"), url(/inc/fonts/NotoSans-Black.ttf) format("truetype");
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: url(/inc/fonts/Roboto-Regular.ttf) format("truetype");
}

/* ==========================================================
 text
========================================================== */
/* txt-cmn-01
---------------------------------------------------------- */
.txt-cmn-01 {
  margin-bottom: 20px;
  line-height: 2;
}

@media screen and (max-width: 768px) {
  .txt-cmn-01 {
    margin-bottom: 10px;
  }
}

.txt-cmn-01:last-of-type {
  margin-bottom: 0;
}

/* txt-highlight-01
---------------------------------------------------------- */
.txt-highlight-01 {
  color: #003399;
  font-weight: bold;
}

@media screen and (min-width: 769px) {
  a.txt-highlight-01:hover {
    text-decoration: underline;
  }
}

/* txt-remark-01, txt-remark-02
---------------------------------------------------------- */
.txt-remark-01, .txt-remark-02 {
  font-size: 13px;
  font-size: 1.3rem;
}

@media screen and (max-width: 768px) {
  .txt-remark-01, .txt-remark-02 {
    font-size: 11px;
    font-size: 1.1rem;
  }
}

.txt-remark-02 {
  color: #ef1616;
}

/* txt-link-arrow-01
---------------------------------------------------------- */
.txt-link-arrow-01 {
  position: relative;
  display: inline-block;
  padding-left: 20px;
  /*@media screen and (max-width: 768px) {
    &:before { top: 3px; }
  }*/
}

.txt-link-arrow-01.white {
  color: #fff;
}

.txt-link-arrow-01:before {
  position: absolute;
  top: 50%;
  left: 0;
  width: 14px;
  height: 14px;
  margin-top: -7px;
  background: url(/inc/image/ico_link_arrow_01_off.png) no-repeat;
  background-size: 14px 14px;
  content: "";
}

@media screen and (min-width: 769px) {
  .txt-link-arrow-01:hover {
    color: #003399;
    text-decoration: underline;
  }
  .txt-link-arrow-01.white:hover {
    color: #fff100;
  }
}

.txt-link-arrow-01.txt-large {
  font-weight: 500;
  font-size: 18px;
  font-size: 1.8rem;
  /*&:before {
      top: 9px;
     }*/
}

@media screen and (max-width: 768px) {
  .txt-link-arrow-01.txt-large {
    font-size: 16px;
    font-size: 1.6rem;
  }
}

/* ==========================================================================
    GRIDLEX
    Just a Flexbox Grid System
========================================================================== */
[class*="grid"] {
  box-sizing: border-box;
  -js-display: flex;
  display: flex;
  flex-flow: row wrap;
  margin: 0 -0.5rem;
}

.ie8 [class*="grid"] {
  padding: 0;
}

.gl,
[class*="gl-"] {
  box-sizing: border-box;
  flex: 0 0 auto;
  padding: 0 0.5rem 1rem;
}

.ie8 .gl, .ie8
[class*="gl-"] {
  padding: 0;
}

.gl {
  flex: 1 1 0%;
}

.grid.gl,
.grid[class*="gl-"] {
  margin: 0;
  padding: 0;
}

/************************
    HELPERS SUFFIXES
*************************/
[class*="grid-"][class*="-noGutter"] {
  margin: 0;
}

[class*="grid-"][class*="-noGutter"] > [class*="gl"] {
  padding: 0;
}

[class*="grid-"][class*="-noWrap"] {
  flex-wrap: nowrap;
}

[class*="grid-"][class*="-center"] {
  justify-content: center;
}

[class*="grid-"][class*="-right"] {
  justify-content: flex-end;
  align-self: flex-end;
  margin-left: auto;
}

[class*="grid-"][class*="-top"] {
  align-items: flex-start;
}

[class*="grid-"][class*="-middle"] {
  align-items: center;
}

[class*="grid-"][class*="-bottom"] {
  align-items: flex-end;
}

[class*="grid-"][class*="-reverse"] {
  flex-direction: row-reverse;
}

[class*="grid-"][class*="-column"] {
  flex-direction: column;
}

[class*="grid-"][class*="-column"] > [class*="gl-"] {
  flex-basis: auto;
}

[class*="grid-"][class*="-column-reverse"] {
  flex-direction: column-reverse;
}

[class*="grid-"][class*="-spaceBetween"] {
  justify-content: space-between;
}

[class*="grid-"][class*="-spaceAround"] {
  justify-content: space-around;
}

[class*="grid-"][class*="-equalHeight"] > [class*="gl"] {
  -js-display: flex;
  display: flex;
}

[class*="grid-"][class*="-equalHeight"] > [class*="gl"] > * {
  flex: 1;
}

[class*="grid-"][class*="-noBottom"] > [class*="gl"] {
  padding-bottom: 0;
}

[class*="gl-"][class*="-top"] {
  align-self: flex-start;
}

[class*="gl-"][class*="-middle"] {
  align-self: center;
}

[class*="gl-"][class*="-bottom"] {
  align-self: flex-end;
}

[class*="gl-"][class*="-first"] {
  order: -1;
}

[class*="gl-"][class*="-last"] {
  order: 1;
}

/************************
    GRID BY NUMBER
*************************/
[class*="grid-1"] > .gl, [class*="grid-1"] > [class*='gl-'] {
  flex-basis: 100%;
  max-width: 100%;
}

[class*="grid-2"] > .gl, [class*="grid-2"] > [class*='gl-'] {
  flex-basis: 50%;
  max-width: 50%;
}

[class*="grid-3"] > .gl, [class*="grid-3"] > [class*='gl-'] {
  flex-basis: 33.33333%;
  max-width: 33.33333%;
}

[class*="grid-4"] > .gl, [class*="grid-4"] > [class*='gl-'] {
  flex-basis: 25%;
  max-width: 25%;
}

[class*="grid-5"] > .gl, [class*="grid-5"] > [class*='gl-'] {
  flex-basis: 20%;
  max-width: 20%;
}

[class*="grid-6"] > .gl, [class*="grid-6"] > [class*='gl-'] {
  flex-basis: 16.66667%;
  max-width: 16.66667%;
}

[class*="grid-7"] > .gl, [class*="grid-7"] > [class*='gl-'] {
  flex-basis: 14.28571%;
  max-width: 14.28571%;
}

[class*="grid-8"] > .gl, [class*="grid-8"] > [class*='gl-'] {
  flex-basis: 12.5%;
  max-width: 12.5%;
}

[class*="grid-9"] > .gl, [class*="grid-9"] > [class*='gl-'] {
  flex-basis: 11.11111%;
  max-width: 11.11111%;
}

[class*="grid-10"] > .gl, [class*="grid-10"] > [class*='gl-'] {
  flex-basis: 10%;
  max-width: 10%;
}

[class*="grid-11"] > .gl, [class*="grid-11"] > [class*='gl-'] {
  flex-basis: 9.09091%;
  max-width: 9.09091%;
}

[class*="grid-12"] > .gl, [class*="grid-12"] > [class*='gl-'] {
  flex-basis: 8.33333%;
  max-width: 8.33333%;
}

@media screen and (max-width: 80em) {
  [class*="_lg-1"] > .gl, [class*="_lg-1"] > [class*='gl-'] {
    flex-basis: 100%;
    max-width: 100%;
  }
  [class*="_lg-2"] > .gl, [class*="_lg-2"] > [class*='gl-'] {
    flex-basis: 50%;
    max-width: 50%;
  }
  [class*="_lg-3"] > .gl, [class*="_lg-3"] > [class*='gl-'] {
    flex-basis: 33.33333%;
    max-width: 33.33333%;
  }
  [class*="_lg-4"] > .gl, [class*="_lg-4"] > [class*='gl-'] {
    flex-basis: 25%;
    max-width: 25%;
  }
  [class*="_lg-5"] > .gl, [class*="_lg-5"] > [class*='gl-'] {
    flex-basis: 20%;
    max-width: 20%;
  }
  [class*="_lg-6"] > .gl, [class*="_lg-6"] > [class*='gl-'] {
    flex-basis: 16.66667%;
    max-width: 16.66667%;
  }
  [class*="_lg-7"] > .gl, [class*="_lg-7"] > [class*='gl-'] {
    flex-basis: 14.28571%;
    max-width: 14.28571%;
  }
  [class*="_lg-8"] > .gl, [class*="_lg-8"] > [class*='gl-'] {
    flex-basis: 12.5%;
    max-width: 12.5%;
  }
  [class*="_lg-9"] > .gl, [class*="_lg-9"] > [class*='gl-'] {
    flex-basis: 11.11111%;
    max-width: 11.11111%;
  }
  [class*="_lg-10"] > .gl, [class*="_lg-10"] > [class*='gl-'] {
    flex-basis: 10%;
    max-width: 10%;
  }
  [class*="_lg-11"] > .gl, [class*="_lg-11"] > [class*='gl-'] {
    flex-basis: 9.09091%;
    max-width: 9.09091%;
  }
  [class*="_lg-12"] > .gl, [class*="_lg-12"] > [class*='gl-'] {
    flex-basis: 8.33333%;
    max-width: 8.33333%;
  }
}

@media screen and (max-width: 64em) {
  [class*="_md-1"] > .gl, [class*="_md-1"] > [class*='gl-'] {
    flex-basis: 100%;
    max-width: 100%;
  }
  [class*="_md-2"] > .gl, [class*="_md-2"] > [class*='gl-'] {
    flex-basis: 50%;
    max-width: 50%;
  }
  [class*="_md-3"] > .gl, [class*="_md-3"] > [class*='gl-'] {
    flex-basis: 33.33333%;
    max-width: 33.33333%;
  }
  [class*="_md-4"] > .gl, [class*="_md-4"] > [class*='gl-'] {
    flex-basis: 25%;
    max-width: 25%;
  }
  [class*="_md-5"] > .gl, [class*="_md-5"] > [class*='gl-'] {
    flex-basis: 20%;
    max-width: 20%;
  }
  [class*="_md-6"] > .gl, [class*="_md-6"] > [class*='gl-'] {
    flex-basis: 16.66667%;
    max-width: 16.66667%;
  }
  [class*="_md-7"] > .gl, [class*="_md-7"] > [class*='gl-'] {
    flex-basis: 14.28571%;
    max-width: 14.28571%;
  }
  [class*="_md-8"] > .gl, [class*="_md-8"] > [class*='gl-'] {
    flex-basis: 12.5%;
    max-width: 12.5%;
  }
  [class*="_md-9"] > .gl, [class*="_md-9"] > [class*='gl-'] {
    flex-basis: 11.11111%;
    max-width: 11.11111%;
  }
  [class*="_md-10"] > .gl, [class*="_md-10"] > [class*='gl-'] {
    flex-basis: 10%;
    max-width: 10%;
  }
  [class*="_md-11"] > .gl, [class*="_md-11"] > [class*='gl-'] {
    flex-basis: 9.09091%;
    max-width: 9.09091%;
  }
  [class*="_md-12"] > .gl, [class*="_md-12"] > [class*='gl-'] {
    flex-basis: 8.33333%;
    max-width: 8.33333%;
  }
}

@media screen and (max-width: 48em) {
  [class*="_sm-1"] > .gl, [class*="_sm-1"] > [class*='gl-'] {
    flex-basis: 100%;
    max-width: 100%;
  }
  [class*="_sm-2"] > .gl, [class*="_sm-2"] > [class*='gl-'] {
    flex-basis: 50%;
    max-width: 50%;
  }
  [class*="_sm-3"] > .gl, [class*="_sm-3"] > [class*='gl-'] {
    flex-basis: 33.33333%;
    max-width: 33.33333%;
  }
  [class*="_sm-4"] > .gl, [class*="_sm-4"] > [class*='gl-'] {
    flex-basis: 25%;
    max-width: 25%;
  }
  [class*="_sm-5"] > .gl, [class*="_sm-5"] > [class*='gl-'] {
    flex-basis: 20%;
    max-width: 20%;
  }
  [class*="_sm-6"] > .gl, [class*="_sm-6"] > [class*='gl-'] {
    flex-basis: 16.66667%;
    max-width: 16.66667%;
  }
  [class*="_sm-7"] > .gl, [class*="_sm-7"] > [class*='gl-'] {
    flex-basis: 14.28571%;
    max-width: 14.28571%;
  }
  [class*="_sm-8"] > .gl, [class*="_sm-8"] > [class*='gl-'] {
    flex-basis: 12.5%;
    max-width: 12.5%;
  }
  [class*="_sm-9"] > .gl, [class*="_sm-9"] > [class*='gl-'] {
    flex-basis: 11.11111%;
    max-width: 11.11111%;
  }
  [class*="_sm-10"] > .gl, [class*="_sm-10"] > [class*='gl-'] {
    flex-basis: 10%;
    max-width: 10%;
  }
  [class*="_sm-11"] > .gl, [class*="_sm-11"] > [class*='gl-'] {
    flex-basis: 9.09091%;
    max-width: 9.09091%;
  }
  [class*="_sm-12"] > .gl, [class*="_sm-12"] > [class*='gl-'] {
    flex-basis: 8.33333%;
    max-width: 8.33333%;
  }
}

@media screen and (max-width: 35.5em) {
  [class*="_xs-1"] > .gl, [class*="_xs-1"] > [class*='gl-'] {
    flex-basis: 100%;
    max-width: 100%;
  }
  [class*="_xs-2"] > .gl, [class*="_xs-2"] > [class*='gl-'] {
    flex-basis: 50%;
    max-width: 50%;
  }
  [class*="_xs-3"] > .gl, [class*="_xs-3"] > [class*='gl-'] {
    flex-basis: 33.33333%;
    max-width: 33.33333%;
  }
  [class*="_xs-4"] > .gl, [class*="_xs-4"] > [class*='gl-'] {
    flex-basis: 25%;
    max-width: 25%;
  }
  [class*="_xs-5"] > .gl, [class*="_xs-5"] > [class*='gl-'] {
    flex-basis: 20%;
    max-width: 20%;
  }
  [class*="_xs-6"] > .gl, [class*="_xs-6"] > [class*='gl-'] {
    flex-basis: 16.66667%;
    max-width: 16.66667%;
  }
  [class*="_xs-7"] > .gl, [class*="_xs-7"] > [class*='gl-'] {
    flex-basis: 14.28571%;
    max-width: 14.28571%;
  }
  [class*="_xs-8"] > .gl, [class*="_xs-8"] > [class*='gl-'] {
    flex-basis: 12.5%;
    max-width: 12.5%;
  }
  [class*="_xs-9"] > .gl, [class*="_xs-9"] > [class*='gl-'] {
    flex-basis: 11.11111%;
    max-width: 11.11111%;
  }
  [class*="_xs-10"] > .gl, [class*="_xs-10"] > [class*='gl-'] {
    flex-basis: 10%;
    max-width: 10%;
  }
  [class*="_xs-11"] > .gl, [class*="_xs-11"] > [class*='gl-'] {
    flex-basis: 9.09091%;
    max-width: 9.09091%;
  }
  [class*="_xs-12"] > .gl, [class*="_xs-12"] > [class*='gl-'] {
    flex-basis: 8.33333%;
    max-width: 8.33333%;
  }
}

/************************
    COLS SIZES
*************************/
[class*='grid'] > [class*="gl-1"] {
  flex-basis: 8.33333%;
  max-width: 8.33333%;
}

[class*='grid'] > [class*="gl-2"] {
  flex-basis: 16.66667%;
  max-width: 16.66667%;
}

[class*='grid'] > [class*="gl-3"] {
  flex-basis: 25%;
  max-width: 25%;
}

[class*='grid'] > [class*="gl-4"] {
  flex-basis: 33.33333%;
  max-width: 33.33333%;
}

[class*='grid'] > [class*="gl-5"] {
  flex-basis: 41.66667%;
  max-width: 41.66667%;
}

[class*='grid'] > [class*="gl-6"] {
  flex-basis: 50%;
  max-width: 50%;
}

[class*='grid'] > [class*="gl-7"] {
  flex-basis: 58.33333%;
  max-width: 58.33333%;
}

[class*='grid'] > [class*="gl-8"] {
  flex-basis: 66.66667%;
  max-width: 66.66667%;
}

[class*='grid'] > [class*="gl-9"] {
  flex-basis: 75%;
  max-width: 75%;
}

[class*='grid'] > [class*="gl-10"] {
  flex-basis: 83.33333%;
  max-width: 83.33333%;
}

[class*='grid'] > [class*="gl-11"] {
  flex-basis: 91.66667%;
  max-width: 91.66667%;
}

[class*='grid'] > [class*="gl-12"] {
  flex-basis: 100%;
  max-width: 100%;
}

[class*="grid"] > [data-push-left*="off-0"] {
  margin-left: 0;
}

[class*="grid"] > [data-push-left*="off-1"] {
  margin-left: 8.33333%;
}

[class*="grid"] > [data-push-left*="off-2"] {
  margin-left: 16.66667%;
}

[class*="grid"] > [data-push-left*="off-3"] {
  margin-left: 25%;
}

[class*="grid"] > [data-push-left*="off-4"] {
  margin-left: 33.33333%;
}

[class*="grid"] > [data-push-left*="off-5"] {
  margin-left: 41.66667%;
}

[class*="grid"] > [data-push-left*="off-6"] {
  margin-left: 50%;
}

[class*="grid"] > [data-push-left*="off-7"] {
  margin-left: 58.33333%;
}

[class*="grid"] > [data-push-left*="off-8"] {
  margin-left: 66.66667%;
}

[class*="grid"] > [data-push-left*="off-9"] {
  margin-left: 75%;
}

[class*="grid"] > [data-push-left*="off-10"] {
  margin-left: 83.33333%;
}

[class*="grid"] > [data-push-left*="off-11"] {
  margin-left: 91.66667%;
}

[class*="grid"] > [data-push-right*="off-0"] {
  margin-right: 0;
}

[class*="grid"] > [data-push-right*="off-1"] {
  margin-right: 8.33333%;
}

[class*="grid"] > [data-push-right*="off-2"] {
  margin-right: 16.66667%;
}

[class*="grid"] > [data-push-right*="off-3"] {
  margin-right: 25%;
}

[class*="grid"] > [data-push-right*="off-4"] {
  margin-right: 33.33333%;
}

[class*="grid"] > [data-push-right*="off-5"] {
  margin-right: 41.66667%;
}

[class*="grid"] > [data-push-right*="off-6"] {
  margin-right: 50%;
}

[class*="grid"] > [data-push-right*="off-7"] {
  margin-right: 58.33333%;
}

[class*="grid"] > [data-push-right*="off-8"] {
  margin-right: 66.66667%;
}

[class*="grid"] > [data-push-right*="off-9"] {
  margin-right: 75%;
}

[class*="grid"] > [data-push-right*="off-10"] {
  margin-right: 83.33333%;
}

[class*="grid"] > [data-push-right*="off-11"] {
  margin-right: 91.66667%;
}

@media screen and (max-width: 80em) {
  [class*='grid'] > [class*="_lg-1"] {
    flex-basis: 8.33333%;
    max-width: 8.33333%;
  }
  [class*='grid'] > [class*="_lg-2"] {
    flex-basis: 16.66667%;
    max-width: 16.66667%;
  }
  [class*='grid'] > [class*="_lg-3"] {
    flex-basis: 25%;
    max-width: 25%;
  }
  [class*='grid'] > [class*="_lg-4"] {
    flex-basis: 33.33333%;
    max-width: 33.33333%;
  }
  [class*='grid'] > [class*="_lg-5"] {
    flex-basis: 41.66667%;
    max-width: 41.66667%;
  }
  [class*='grid'] > [class*="_lg-6"] {
    flex-basis: 50%;
    max-width: 50%;
  }
  [class*='grid'] > [class*="_lg-7"] {
    flex-basis: 58.33333%;
    max-width: 58.33333%;
  }
  [class*='grid'] > [class*="_lg-8"] {
    flex-basis: 66.66667%;
    max-width: 66.66667%;
  }
  [class*='grid'] > [class*="_lg-9"] {
    flex-basis: 75%;
    max-width: 75%;
  }
  [class*='grid'] > [class*="_lg-10"] {
    flex-basis: 83.33333%;
    max-width: 83.33333%;
  }
  [class*='grid'] > [class*="_lg-11"] {
    flex-basis: 91.66667%;
    max-width: 91.66667%;
  }
  [class*='grid'] > [class*="_lg-12"] {
    flex-basis: 100%;
    max-width: 100%;
  }
  [class*="grid"] > [data-push-left*="_lg-0"] {
    margin-left: 0;
  }
  [class*="grid"] > [data-push-left*="_lg-1"] {
    margin-left: 8.33333%;
  }
  [class*="grid"] > [data-push-left*="_lg-2"] {
    margin-left: 16.66667%;
  }
  [class*="grid"] > [data-push-left*="_lg-3"] {
    margin-left: 25%;
  }
  [class*="grid"] > [data-push-left*="_lg-4"] {
    margin-left: 33.33333%;
  }
  [class*="grid"] > [data-push-left*="_lg-5"] {
    margin-left: 41.66667%;
  }
  [class*="grid"] > [data-push-left*="_lg-6"] {
    margin-left: 50%;
  }
  [class*="grid"] > [data-push-left*="_lg-7"] {
    margin-left: 58.33333%;
  }
  [class*="grid"] > [data-push-left*="_lg-8"] {
    margin-left: 66.66667%;
  }
  [class*="grid"] > [data-push-left*="_lg-9"] {
    margin-left: 75%;
  }
  [class*="grid"] > [data-push-left*="_lg-10"] {
    margin-left: 83.33333%;
  }
  [class*="grid"] > [data-push-left*="_lg-11"] {
    margin-left: 91.66667%;
  }
  [class*="grid"] > [data-push-right*="_lg-0"] {
    margin-right: 0;
  }
  [class*="grid"] > [data-push-right*="_lg-1"] {
    margin-right: 8.33333%;
  }
  [class*="grid"] > [data-push-right*="_lg-2"] {
    margin-right: 16.66667%;
  }
  [class*="grid"] > [data-push-right*="_lg-3"] {
    margin-right: 25%;
  }
  [class*="grid"] > [data-push-right*="_lg-4"] {
    margin-right: 33.33333%;
  }
  [class*="grid"] > [data-push-right*="_lg-5"] {
    margin-right: 41.66667%;
  }
  [class*="grid"] > [data-push-right*="_lg-6"] {
    margin-right: 50%;
  }
  [class*="grid"] > [data-push-right*="_lg-7"] {
    margin-right: 58.33333%;
  }
  [class*="grid"] > [data-push-right*="_lg-8"] {
    margin-right: 66.66667%;
  }
  [class*="grid"] > [data-push-right*="_lg-9"] {
    margin-right: 75%;
  }
  [class*="grid"] > [data-push-right*="_lg-10"] {
    margin-right: 83.33333%;
  }
  [class*="grid"] > [data-push-right*="_lg-11"] {
    margin-right: 91.66667%;
  }
}

@media screen and (max-width: 64em) {
  [class*='grid'] > [class*="_md-1"] {
    flex-basis: 8.33333%;
    max-width: 8.33333%;
  }
  [class*='grid'] > [class*="_md-2"] {
    flex-basis: 16.66667%;
    max-width: 16.66667%;
  }
  [class*='grid'] > [class*="_md-3"] {
    flex-basis: 25%;
    max-width: 25%;
  }
  [class*='grid'] > [class*="_md-4"] {
    flex-basis: 33.33333%;
    max-width: 33.33333%;
  }
  [class*='grid'] > [class*="_md-5"] {
    flex-basis: 41.66667%;
    max-width: 41.66667%;
  }
  [class*='grid'] > [class*="_md-6"] {
    flex-basis: 50%;
    max-width: 50%;
  }
  [class*='grid'] > [class*="_md-7"] {
    flex-basis: 58.33333%;
    max-width: 58.33333%;
  }
  [class*='grid'] > [class*="_md-8"] {
    flex-basis: 66.66667%;
    max-width: 66.66667%;
  }
  [class*='grid'] > [class*="_md-9"] {
    flex-basis: 75%;
    max-width: 75%;
  }
  [class*='grid'] > [class*="_md-10"] {
    flex-basis: 83.33333%;
    max-width: 83.33333%;
  }
  [class*='grid'] > [class*="_md-11"] {
    flex-basis: 91.66667%;
    max-width: 91.66667%;
  }
  [class*='grid'] > [class*="_md-12"] {
    flex-basis: 100%;
    max-width: 100%;
  }
  [class*="grid"] > [data-push-left*="_md-0"] {
    margin-left: 0;
  }
  [class*="grid"] > [data-push-left*="_md-1"] {
    margin-left: 8.33333%;
  }
  [class*="grid"] > [data-push-left*="_md-2"] {
    margin-left: 16.66667%;
  }
  [class*="grid"] > [data-push-left*="_md-3"] {
    margin-left: 25%;
  }
  [class*="grid"] > [data-push-left*="_md-4"] {
    margin-left: 33.33333%;
  }
  [class*="grid"] > [data-push-left*="_md-5"] {
    margin-left: 41.66667%;
  }
  [class*="grid"] > [data-push-left*="_md-6"] {
    margin-left: 50%;
  }
  [class*="grid"] > [data-push-left*="_md-7"] {
    margin-left: 58.33333%;
  }
  [class*="grid"] > [data-push-left*="_md-8"] {
    margin-left: 66.66667%;
  }
  [class*="grid"] > [data-push-left*="_md-9"] {
    margin-left: 75%;
  }
  [class*="grid"] > [data-push-left*="_md-10"] {
    margin-left: 83.33333%;
  }
  [class*="grid"] > [data-push-left*="_md-11"] {
    margin-left: 91.66667%;
  }
  [class*="grid"] > [data-push-right*="_md-0"] {
    margin-right: 0;
  }
  [class*="grid"] > [data-push-right*="_md-1"] {
    margin-right: 8.33333%;
  }
  [class*="grid"] > [data-push-right*="_md-2"] {
    margin-right: 16.66667%;
  }
  [class*="grid"] > [data-push-right*="_md-3"] {
    margin-right: 25%;
  }
  [class*="grid"] > [data-push-right*="_md-4"] {
    margin-right: 33.33333%;
  }
  [class*="grid"] > [data-push-right*="_md-5"] {
    margin-right: 41.66667%;
  }
  [class*="grid"] > [data-push-right*="_md-6"] {
    margin-right: 50%;
  }
  [class*="grid"] > [data-push-right*="_md-7"] {
    margin-right: 58.33333%;
  }
  [class*="grid"] > [data-push-right*="_md-8"] {
    margin-right: 66.66667%;
  }
  [class*="grid"] > [data-push-right*="_md-9"] {
    margin-right: 75%;
  }
  [class*="grid"] > [data-push-right*="_md-10"] {
    margin-right: 83.33333%;
  }
  [class*="grid"] > [data-push-right*="_md-11"] {
    margin-right: 91.66667%;
  }
}

@media screen and (max-width: 48em) {
  [class*='grid'] > [class*="_sm-1"] {
    flex-basis: 8.33333%;
    max-width: 8.33333%;
  }
  [class*='grid'] > [class*="_sm-2"] {
    flex-basis: 16.66667%;
    max-width: 16.66667%;
  }
  [class*='grid'] > [class*="_sm-3"] {
    flex-basis: 25%;
    max-width: 25%;
  }
  [class*='grid'] > [class*="_sm-4"] {
    flex-basis: 33.33333%;
    max-width: 33.33333%;
  }
  [class*='grid'] > [class*="_sm-5"] {
    flex-basis: 41.66667%;
    max-width: 41.66667%;
  }
  [class*='grid'] > [class*="_sm-6"] {
    flex-basis: 50%;
    max-width: 50%;
  }
  [class*='grid'] > [class*="_sm-7"] {
    flex-basis: 58.33333%;
    max-width: 58.33333%;
  }
  [class*='grid'] > [class*="_sm-8"] {
    flex-basis: 66.66667%;
    max-width: 66.66667%;
  }
  [class*='grid'] > [class*="_sm-9"] {
    flex-basis: 75%;
    max-width: 75%;
  }
  [class*='grid'] > [class*="_sm-10"] {
    flex-basis: 83.33333%;
    max-width: 83.33333%;
  }
  [class*='grid'] > [class*="_sm-11"] {
    flex-basis: 91.66667%;
    max-width: 91.66667%;
  }
  [class*='grid'] > [class*="_sm-12"] {
    flex-basis: 100%;
    max-width: 100%;
  }
  [class*="grid"] > [data-push-left*="_sm-0"] {
    margin-left: 0;
  }
  [class*="grid"] > [data-push-left*="_sm-1"] {
    margin-left: 8.33333%;
  }
  [class*="grid"] > [data-push-left*="_sm-2"] {
    margin-left: 16.66667%;
  }
  [class*="grid"] > [data-push-left*="_sm-3"] {
    margin-left: 25%;
  }
  [class*="grid"] > [data-push-left*="_sm-4"] {
    margin-left: 33.33333%;
  }
  [class*="grid"] > [data-push-left*="_sm-5"] {
    margin-left: 41.66667%;
  }
  [class*="grid"] > [data-push-left*="_sm-6"] {
    margin-left: 50%;
  }
  [class*="grid"] > [data-push-left*="_sm-7"] {
    margin-left: 58.33333%;
  }
  [class*="grid"] > [data-push-left*="_sm-8"] {
    margin-left: 66.66667%;
  }
  [class*="grid"] > [data-push-left*="_sm-9"] {
    margin-left: 75%;
  }
  [class*="grid"] > [data-push-left*="_sm-10"] {
    margin-left: 83.33333%;
  }
  [class*="grid"] > [data-push-left*="_sm-11"] {
    margin-left: 91.66667%;
  }
  [class*="grid"] > [data-push-right*="_sm-0"] {
    margin-right: 0;
  }
  [class*="grid"] > [data-push-right*="_sm-1"] {
    margin-right: 8.33333%;
  }
  [class*="grid"] > [data-push-right*="_sm-2"] {
    margin-right: 16.66667%;
  }
  [class*="grid"] > [data-push-right*="_sm-3"] {
    margin-right: 25%;
  }
  [class*="grid"] > [data-push-right*="_sm-4"] {
    margin-right: 33.33333%;
  }
  [class*="grid"] > [data-push-right*="_sm-5"] {
    margin-right: 41.66667%;
  }
  [class*="grid"] > [data-push-right*="_sm-6"] {
    margin-right: 50%;
  }
  [class*="grid"] > [data-push-right*="_sm-7"] {
    margin-right: 58.33333%;
  }
  [class*="grid"] > [data-push-right*="_sm-8"] {
    margin-right: 66.66667%;
  }
  [class*="grid"] > [data-push-right*="_sm-9"] {
    margin-right: 75%;
  }
  [class*="grid"] > [data-push-right*="_sm-10"] {
    margin-right: 83.33333%;
  }
  [class*="grid"] > [data-push-right*="_sm-11"] {
    margin-right: 91.66667%;
  }
}

@media screen and (max-width: 35.5em) {
  [class*='grid'] > [class*="_xs-1"] {
    flex-basis: 8.33333%;
    max-width: 8.33333%;
  }
  [class*='grid'] > [class*="_xs-2"] {
    flex-basis: 16.66667%;
    max-width: 16.66667%;
  }
  [class*='grid'] > [class*="_xs-3"] {
    flex-basis: 25%;
    max-width: 25%;
  }
  [class*='grid'] > [class*="_xs-4"] {
    flex-basis: 33.33333%;
    max-width: 33.33333%;
  }
  [class*='grid'] > [class*="_xs-5"] {
    flex-basis: 41.66667%;
    max-width: 41.66667%;
  }
  [class*='grid'] > [class*="_xs-6"] {
    flex-basis: 50%;
    max-width: 50%;
  }
  [class*='grid'] > [class*="_xs-7"] {
    flex-basis: 58.33333%;
    max-width: 58.33333%;
  }
  [class*='grid'] > [class*="_xs-8"] {
    flex-basis: 66.66667%;
    max-width: 66.66667%;
  }
  [class*='grid'] > [class*="_xs-9"] {
    flex-basis: 75%;
    max-width: 75%;
  }
  [class*='grid'] > [class*="_xs-10"] {
    flex-basis: 83.33333%;
    max-width: 83.33333%;
  }
  [class*='grid'] > [class*="_xs-11"] {
    flex-basis: 91.66667%;
    max-width: 91.66667%;
  }
  [class*='grid'] > [class*="_xs-12"] {
    flex-basis: 100%;
    max-width: 100%;
  }
  [class*="grid"] > [data-push-left*="_xs-0"] {
    margin-left: 0;
  }
  [class*="grid"] > [data-push-left*="_xs-1"] {
    margin-left: 8.33333%;
  }
  [class*="grid"] > [data-push-left*="_xs-2"] {
    margin-left: 16.66667%;
  }
  [class*="grid"] > [data-push-left*="_xs-3"] {
    margin-left: 25%;
  }
  [class*="grid"] > [data-push-left*="_xs-4"] {
    margin-left: 33.33333%;
  }
  [class*="grid"] > [data-push-left*="_xs-5"] {
    margin-left: 41.66667%;
  }
  [class*="grid"] > [data-push-left*="_xs-6"] {
    margin-left: 50%;
  }
  [class*="grid"] > [data-push-left*="_xs-7"] {
    margin-left: 58.33333%;
  }
  [class*="grid"] > [data-push-left*="_xs-8"] {
    margin-left: 66.66667%;
  }
  [class*="grid"] > [data-push-left*="_xs-9"] {
    margin-left: 75%;
  }
  [class*="grid"] > [data-push-left*="_xs-10"] {
    margin-left: 83.33333%;
  }
  [class*="grid"] > [data-push-left*="_xs-11"] {
    margin-left: 91.66667%;
  }
  [class*="grid"] > [data-push-right*="_xs-0"] {
    margin-right: 0;
  }
  [class*="grid"] > [data-push-right*="_xs-1"] {
    margin-right: 8.33333%;
  }
  [class*="grid"] > [data-push-right*="_xs-2"] {
    margin-right: 16.66667%;
  }
  [class*="grid"] > [data-push-right*="_xs-3"] {
    margin-right: 25%;
  }
  [class*="grid"] > [data-push-right*="_xs-4"] {
    margin-right: 33.33333%;
  }
  [class*="grid"] > [data-push-right*="_xs-5"] {
    margin-right: 41.66667%;
  }
  [class*="grid"] > [data-push-right*="_xs-6"] {
    margin-right: 50%;
  }
  [class*="grid"] > [data-push-right*="_xs-7"] {
    margin-right: 58.33333%;
  }
  [class*="grid"] > [data-push-right*="_xs-8"] {
    margin-right: 66.66667%;
  }
  [class*="grid"] > [data-push-right*="_xs-9"] {
    margin-right: 75%;
  }
  [class*="grid"] > [data-push-right*="_xs-10"] {
    margin-right: 83.33333%;
  }
  [class*="grid"] > [data-push-right*="_xs-11"] {
    margin-right: 91.66667%;
  }
}

/************************
    HIDING COLS
*************************/
[class*="gl-"]:not([class*="gl-0"]) {
  display: block;
}

[class*="grid"][class*="gl-"]:not([class*="gl-0"]) {
  -js-display: flex;
  display: flex;
}

[class*="gl-"][class*="gl-0"] {
  display: none;
}

@media screen and (max-width: 80em) {
  [class*="grid"] > :not([class*="_lg-0"]) {
    display: block;
  }
  [class*="grid"]:not([class*="_lg-0"]) {
    -js-display: flex;
    display: flex;
  }
  [class*="grid"] > [class*="_lg-0"],
  [class*="grid"][class*="-equalHeight"] > [class*="_lg-0"] {
    display: none;
  }
}

@media screen and (max-width: 64em) {
  [class*="grid"] > :not([class*="_md-0"]) {
    display: block;
  }
  [class*="grid"]:not([class*="_md-0"]) {
    -js-display: flex;
    display: flex;
  }
  [class*="grid"] > [class*="_md-0"],
  [class*="grid"][class*="-equalHeight"] > [class*="_md-0"] {
    display: none;
  }
}

@media screen and (max-width: 48em) {
  [class*="grid"] > :not([class*="_sm-0"]) {
    display: block;
  }
  [class*="grid"]:not([class*="_sm-0"]) {
    -js-display: flex;
    display: flex;
  }
  [class*="grid"] > [class*="_sm-0"],
  [class*="grid"][class*="-equalHeight"] > [class*="_sm-0"] {
    display: none;
  }
}

@media screen and (max-width: 35.5em) {
  [class*="grid"] > :not([class*="_xs-0"]) {
    display: block;
  }
  [class*="grid"]:not([class*="_xs-0"]) {
    -js-display: flex;
    display: flex;
  }
  [class*="grid"] > [class*="_xs-0"],
  [class*="grid"][class*="-equalHeight"] > [class*="_xs-0"] {
    display: none;
  }
}

/* ==========================================================
 header
========================================================== */
.header {
  position: fixed;
  z-index: 1000;
  width: 100%;
  top: 0;
  background-color: #fff;
}

.fixedheader .header {
  box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.16);
}

@media screen and (min-width: 769px) {
  .fixedheader .header .header-logo {
    top: 11px;
  }
}

.fixedheader .header .header-logo img.only-pc {
  width: 90px;
}

.fixedheader .header .header-in {
  height: 55px;
}

.fixedheader .header .nav-global,
.fixedheader .header .btn-lang {
  top: 15px;
}

.fixedheader .header .mod-mega-menu {
  top: 40px;
}

.fixedheader .header .txt-header {
  margin-top: 7px;
  font-size: 20px;
  font-size: 2rem;
}

.fixedheader.fixedSubNavi .header {
  box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.16);
}

@media screen and (min-width: 769px) {
  .header:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 50%;
    height: 6px;
    background-color: #003399;
  }
}

.header .header-in {
  position: relative;
  margin: 0 auto;
  border-top: 6px solid #003399;
  transition: height 0.2s;
}

.header .header-in:before {
  content: "";
  position: absolute;
  top: -6px;
  right: 0;
  width: 168px;
  height: 6px;
  background-image: url(/inc/image/bg_line_yellow_pc.png);
  background-color: #fff;
  background-size: 100% auto;
  background-position: 0 0;
  background-repeat: no-repeat;
}

@media screen and (min-width: 769px) {
  .header .header-in {
    max-width: 1200px;
    height: 80px;
  }
}

@media screen and (max-width: 768px) {
  .header .header-in {
    height: 60px;
    border-width: 5px;
  }
  .header .header-in:before {
    top: -5px;
    width: 49px;
    height: 5px;
    background-image: url(/inc/image/bg_line_yellow_sp.png);
  }
}

.header .header-logo {
  transition: top 0.2s;
  position: absolute;
  width: 120px;
  top: 20px;
  left: 20px;
  z-index: 2;
  display: inline-block;
}

.header .header-logo img {
  transition: width 0.2s;
  width: 100%;
}

.header .header-logo a {
  display: inline-block;
  -webkit-transition: opacity 0.15s ease 0s;
  transition: opacity 0.15s ease 0s;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.header .header-logo a:hover {
  opacity: 0.7;
}

@media screen and (max-width: 768px) {
  .header .header-logo a:hover {
    opacity: 1;
  }
}

@media screen and (max-width: 768px) {
  .header .header-logo {
    width: 90px;
    top: 16px;
    left: 50%;
    transform: translateX(-50%);
  }
}

.header #header-right {
  position: absolute;
  background-color: #fff;
  top: 25px;
  right: 20px;
  z-index: 2;
  text-align: right;
  transition: top 0.2s;
}

.fixedheader .header #header-right {
  top: 12px;
}

.header #header-right #sitesearch {
  max-width: 24px;
  vertical-align: top;
  border-right: 1px dotted #ccc;
  margin-right: 3px;
  padding-right: 5px;
}

.header #header-right #sitesearch form {
  display: inline-block;
}

.header #header-right #sitesearch form input {
  width: 130px;
  font-size: 13px;
  border-radius: 4px;
  border: 1px solid #bbb;
}

.header #header-right #sitesearch i {
  text-align: center;
  font-size: 14px;
  width: 20px;
  height: 20px;
}

.header #header-right .btn-lang {
  max-width: 19px;
  vertical-align: middle;
  line-height: 1;
}

.header #header-right #sitesearch,
.header #header-right .btn-lang {
  display: inline-block;
  transition: max-width .6s;
  overflow: hidden;
  white-space: nowrap;
  opacity: .5;
}

.header #header-right #sitesearch:hover,
.header #header-right .btn-lang:hover {
  max-width: 100%;
  opacity: 1;
}

.header .btn-nav-global {
  position: absolute;
  top: 20px;
  right: 16px;
  width: 25px;
  height: 18px;
}

.header .btn-nav-global .bar {
  position: absolute;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #003399;
  transition: all .25s ease-in;
}

.header .btn-nav-global .bar:nth-child(1) {
  top: 0;
}

.header .btn-nav-global .bar:nth-child(2) {
  top: 8px;
}

.header .btn-nav-global .bar:nth-child(3) {
  bottom: 0;
}

.header .btn-nav-global.is-open .bar:nth-child(1) {
  transform: rotate(45deg) translate(4px, 6px);
}

.header .btn-nav-global.is-open .bar:nth-child(2) {
  transform: scaleX(0);
}

.header .btn-nav-global.is-open .bar:nth-child(3) {
  transform: rotate(-45deg) translate(5px, -7px);
}

.header .txt-header {
  float: right;
  margin: 15px 20px 0 0;
  color: #003399;
  font-weight: 500;
  font-size: 26px;
  font-size: 2.6rem;
}

@media screen and (max-width: 768px) {
  .header .txt-header {
    float: none;
    margin: 0;
    font-size: 22px;
    font-size: 2.2rem;
    text-align: center;
  }
}

.header.contact {
  border-bottom: 1px solid #ccc;
}

.header.contact .header-in {
  max-width: 1000px;
}

@media screen and (max-width: 768px) {
  .header.contact .header-in {
    height: auto;
    padding: 45px 0 10px;
  }
}

/* ==========================================================
 footer
========================================================== */
.footer {
  position: relative;
  width: 100%;
  background-color: #fff;
  transition: transform 0.2s ease-in;
}

.footer:after {
  content: "";
  display: table;
  clear: both;
}

.footer.bg-white {
  background-color: #fff;
}

@media screen and (min-width: 769px) {
  .footer {
    min-width: 1120px;
    background-color: #eee;
  }
}

@media screen and (min-width: 769px) {
  .footer .footer-container {
    width: 95%;
    margin: 0 auto;
    position: relative;
  }
  .footer .footer-container .sns-link {
    position: absolute;
    bottom: 0;
    right: 0;
  }
  .footer .footer-container .sns-link li {
    display: inline-block;
    vertical-align: middle;
  }
}

@media screen and (max-width: 768px) {
  .footer .footer-container {
    padding-right: 18px;
    padding-left: 18px;
  }
}

.footer .txt-link-footer {
  display: inline-block;
  font-size: 14px;
  font-size: 1.4rem;
  font-weight: 500;
  color: #393939;
  line-height: 1.1;
}

.footer .txt-link-footer:hover {
  color: #003399;
  text-decoration: underline;
}

.footer .txt-link-footer.blank {
  padding-right: 20px;
  background-image: url(/inc/image/ico_new_window.png);
  background-size: 12px;
  background-position: right center;
  background-repeat: no-repeat;
}

.footer-top {
  padding-top: 35px;
  padding-bottom: 20px;
}

@media screen and (max-width: 768px) {
  .footer-top {
    padding-bottom: 0;
  }
}

.footer-top .footer-logo {
  display: inline-block;
  margin-bottom: 48px;
}

@media screen and (max-width: 768px) {
  .footer-top .footer-logo {
    position: relative;
    left: 50%;
    margin-left: -58px;
    margin-bottom: 35px;
  }
}

.footer-top .footer-logo a {
  display: block;
  width: 120px;
  -webkit-transition: opacity 0.15s ease 0s;
  transition: opacity 0.15s ease 0s;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.footer-top .footer-logo a:hover {
  opacity: 0.7;
}

@media screen and (max-width: 768px) {
  .footer-top .footer-logo a:hover {
    opacity: 1;
  }
}

@media screen and (max-width: 768px) {
  .footer-top .footer-logo a {
    width: 115px;
  }
}

.footer-top .footer-logo img {
  width: 100%;
}

.footer-nav {
  overflow: hidden;
}

.footer-nav .item {
  overflow: hidden;
}

@media screen and (min-width: 769px) {
  .footer-nav .item {
    float: left;
    margin-left: 30px;
  }
  .footer-nav .item:nth-child(1) {
    margin-left: 0;
  }
}

@media screen and (max-width: 768px) {
  .footer-nav .item {
    margin-bottom: 20px;
  }
}

.footer-nav .title {
  line-height: 1;
}

@media screen and (min-width: 769px) {
  .footer-nav .title {
    margin-bottom: 10px;
    padding-bottom: 15px;
    border-bottom: 1px solid #cbcbcb;
  }
}

@media screen and (min-width: 769px) {
  .footer-nav .list-links {
    float: left;
    margin-bottom: 65px;
    margin-left: 3px;
    margin-right: 30px;
  }
}

@media screen and (max-width: 768px) {
  .footer-nav .list-links {
    display: none;
  }
}

.footer-nav .list-links li {
  line-height: 1;
}

.footer-nav .list-links a {
  display: inline-block;
  font-size: 12px;
  font-size: 1.2rem;
  line-height: 2;
  color: #7a7a7a;
}

.footer-nav .list-links a:hover {
  color: #003399;
  text-decoration: underline;
}

@media screen and (min-width: 769px) {
  .footer-links-01 {
    margin-left: -30px;
    overflow: hidden;
  }
}

.footer-links-01 li {
  line-height: 1;
}

@media screen and (min-width: 769px) {
  .footer-links-01 li {
    float: left;
    margin-left: 30px;
  }
}

@media screen and (max-width: 768px) {
  .footer-links-01 li {
    margin-bottom: 20px;
  }
}

.footer-bottom {
  overflow: hidden;
  border-top: 1px solid #cbcbcb;
}

.footer-bottom .footer-container {
  position: relative;
  padding-top: 10px;
}

.footer-bottom .footer-copyright {
  font-size: 11px;
  font-size: 1.1rem;
  color: #a5a5a5;
  line-height: 1.1;
}

@media screen and (min-width: 769px) {
  .footer-bottom .footer-container {
    height: 70px;
  }
  .footer-bottom .footer-container:after {
    content: "";
    display: table;
    clear: both;
  }
  .footer-bottom .footer-links-02 {
    float: left;
  }
  .footer-bottom .footer-copyright {
    float: right;
  }
}

@media screen and (max-width: 768px) {
  .footer-bottom .footer-container {
    padding-bottom: 20px;
  }
  .footer-bottom .footer-container:before {
    right: 0;
    width: 74px;
    background-image: url(/inc/image/bg_line_yellow_02_sp.png);
    background-color: #fff;
  }
  .footer-bottom .footer-links-02 {
    margin-bottom: 20px;
  }
  .footer-bottom .footer-links-02 li {
    display: inline-block;
    margin-right: 1em;
  }
  .footer-bottom .footer-copyright {
    text-align: center;
  }
}

@media screen and (min-width: 769px) {
  .footer-links-02 {
    overflow: hidden;
    margin-left: -40px;
  }
}

.footer-links-02 li {
  line-height: 1;
}

@media screen and (min-width: 769px) {
  .footer-links-02 li {
    float: left;
    margin-left: 40px;
  }
}

.footer-links-02 a {
  display: inline-block;
  font-size: 12px;
  font-size: 1.2rem;
  color: #7a7a7a;
  line-height: 1.1;
}

.footer-links-02 a:hover {
  color: #003399;
  text-decoration: underline;
}

@media screen and (max-width: 768px) {
  .footer-links-02 a {
    line-height: 2;
  }
}

.mod-mega-menu .link-cate-01 > a, .mod-mega-menu .list-cate-02 a, .mod-mega-menu .list-cate-03 a {
  border-radius: 4px;
  background-color: #fff;
  transition: background-color 0.25s, box-shadow 0.25s;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
}

.mod-mega-menu .link-cate-01 > a:hover, .mod-mega-menu .list-cate-02 a:hover, .mod-mega-menu .list-cate-03 a:hover {
  background-color: rgba(255, 255, 255, 0.9);
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2);
}

/* ==========================================================
 nav-global
========================================================== */
.nav-global {
  position: absolute;
  top: 27px;
  left: 0;
  width: 100%;
  transition: top 0.2s;
}

@media screen and (max-width: 768px) {
  .nav-global {
    display: none;
  }
}

@media screen and (min-width: 769px) {
  .list-nav-global {
    width: 1200px;
    margin: 0 auto;
    padding-left: 185px;
    overflow: hidden;
  }
}

@media screen and (min-width: 769px) {
  .list-nav-global .item {
    float: left;
    height: 40px;
    line-height: 1;
    margin-left: 1px;
    border-radius: 3px 3px 0 0;
    transition: height .2s;
  }
  .list-nav-global .item.nav01 {
    padding-top: 8px;
    margin-right: 10px;
  }
  .list-nav-global .item.is-active {
    background-color: #003399;
    height: 52px;
  }
  .list-nav-global .item.is-active > a {
    color: #ffff00;
  }
  .list-nav-global .item.is-active .ico-blank:after {
    background-image: url(/inc/image/top/ico_blank_02.png);
  }
}

@media screen and (min-width: 769px) {
  .list-nav-global .item > a {
    display: inline-block;
    padding: 8px 15px;
    border-radius: 3px;
    font-size: 15px;
    font-size: 1.5rem;
    font-weight: 500;
    line-height: 1.1;
    color: #003399;
    transition: background-color .2s;
  }
  .list-nav-global .item > a.home {
    width: 18px;
    padding: 0;
    background: url(/inc/image/ico_home_01.png) 0 0 no-repeat;
    background-size: 100% auto;
    overflow: hidden;
    text-indent: -9999px;
    -webkit-transition: opacity 0.15s ease 0s;
    transition: opacity 0.15s ease 0s;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }
  .list-nav-global .item > a.home:hover {
    opacity: 0.7;
  }
}

@media screen and (min-width: 769px) and (max-width: 768px) {
  .list-nav-global .item > a.home:hover {
    opacity: 1;
  }
}

@media screen and (min-width: 769px) {
  .list-nav-global .item > a:hover:not(.home), .list-nav-global .item > a.is-active:not(.home) {
    background-color: #003399;
    color: #ffff00;
  }
  .list-nav-global .item > a:hover.ico-blank:after, .list-nav-global .item > a.is-active.ico-blank:after {
    background-image: url(/inc/image/top/ico_blank_02.png);
  }
}

.mod-mega-menu {
  display: none;
  background-color: #003399;
}

@media screen and (min-width: 769px) {
  .mod-mega-menu {
    position: absolute;
    top: 52px;
    left: 0;
    width: 100%;
    z-index: 99;
    background-size: auto 100%;
    background-position: right center;
    background-repeat: no-repeat;
  }
  .mod-mega-menu.mega-menu-01 {
    background-image: url(/inc/image/bg_megamenu_01.jpg);
  }
  .mod-mega-menu.mega-menu-02 {
    background-image: url(/inc/image/bg_megamenu_02.jpg);
  }
  .mod-mega-menu.mega-menu-02 .box-inner {
    padding-right: 320px;
  }
  .mod-mega-menu.mega-menu-03 {
    background-image: url(/inc/image/bg_megamenu_03.jpg);
  }
  .mod-mega-menu.mega-menu-04 {
    background-image: url(/inc/image/bg_megamenu_04.jpg);
  }
}

.mod-mega-menu .box-inner {
  color: #fff;
  overflow: hidden;
}

@media screen and (min-width: 769px) {
  .mod-mega-menu .box-inner {
    width: 1200px;
    height: 280px;
    margin: 0 auto;
    padding: 20px;
  }
}

.mod-mega-menu .grid-megamenu-nav-01 {
  overflow: hidden;
}

@media screen and (min-width: 769px) {
  .mod-mega-menu .grid-megamenu-nav-01 {
    height: 100%;
  }
}

@media screen and (min-width: 769px) {
  .mod-mega-menu .grid-megamenu-nav-01 .col {
    float: left;
    width: 200px;
    height: 100%;
    margin-right: 10px;
  }
}

.mod-mega-menu .list-cate-01 > li:not(:last-child) {
  margin-bottom: 7px;
}

@media screen and (min-width: 769px) {
  .mod-mega-menu .link-cate-01:not(:last-child) .list-links {
    margin-bottom: 11px;
  }
}

.mod-mega-menu .link-cate-01.hasChild > a {
  border-radius: 5px 5px 0 0;
}

@media screen and (min-width: 769px) {
  .mod-mega-menu .link-cate-01 {
    position: relative;
    overflow: hidden;
  }
  .mod-mega-menu .link-cate-01.full-height {
    height: 100%;
  }
}

.mod-mega-menu .link-cate-01 > a {
  display: block;
  line-height: 1;
}

.mod-mega-menu .link-cate-01 > a.is-active {
  color: #fff000;
  background-color: rgba(255, 255, 255, 0.2);
}

@media screen and (min-width: 769px) {
  .mod-mega-menu .link-cate-01 > a {
    padding: 19px 5px;
    font-size: 15px;
    font-size: 1.5rem;
    font-weight: 500;
    color: #003399;
    text-align: center;
  }
}

.mod-mega-menu .link-cate-01 .list-links {
  margin-top: 5px;
}

.mod-mega-menu .list-links li {
  padding: 6px 10px;
  transition: background-color 0.25s;
}

.mod-mega-menu .list-links li:not(:last-child) {
  margin-bottom: 3px;
}

.mod-mega-menu .list-links li a, .mod-mega-menu .list-links li span {
  display: inline-block;
}

@media screen and (min-width: 769px) {
  .mod-mega-menu .list-links li a, .mod-mega-menu .list-links li span {
    font-size: 13px;
    font-size: 1.3rem;
    line-height: 1.3;
    color: #fff;
  }
  .mod-mega-menu .list-links li a:not(span):hover, .mod-mega-menu .list-links li span:not(span):hover {
    color: #fff000;
    text-decoration: underline;
  }
}

.mod-mega-menu .list-links li:hover {
  border-radius: 5px;
  background-color: rgba(255, 255, 255, 0.1);
}

@media screen and (min-width: 769px) {
  .mod-mega-menu .grid-megamenu-nav-02 {
    overflow: hidden;
  }
  .mod-mega-menu .grid-megamenu-nav-02 .col {
    width: auto;
  }
}

.mod-mega-menu .list-cate-02 {
  overflow: hidden;
}

.mod-mega-menu .list-cate-02 li {
  margin: 0 3px 3px 0;
}

@media screen and (min-width: 769px) {
  .mod-mega-menu .list-cate-02 li {
    float: left;
    width: 24%;
  }
}

.mod-mega-menu .list-cate-02 a {
  position: relative;
  display: table;
  width: 100%;
  line-height: 1;
}

@media screen and (min-width: 769px) {
  .mod-mega-menu .list-cate-02 a {
    height: 80px;
    background-color: #fff;
    font-weight: 500;
    color: #003399;
  }
}

.mod-mega-menu .list-cate-02 a.is-active {
  background-color: #fff000;
  border: 1px solid #fff000;
}

.mod-mega-menu .list-cate-02 .image {
  position: absolute;
  top: 50%;
  left: 0;
  width: 80px;
  height: 100%;
  transform: translateY(-50%);
}

.mod-mega-menu .list-cate-02 .image img {
  height: 100%;
  border-radius: 5px 0 0 5px;
}

.mod-mega-menu .list-cate-02 .text {
  display: table-cell;
  vertical-align: middle;
  padding-left: 100px;
}

@media screen and (min-width: 769px) {
  .mod-mega-menu .list-cate-03 {
    width: 830px;
    overflow: hidden;
  }
}

@media screen and (min-width: 769px) {
  .mod-mega-menu .list-cate-03 li {
    float: left;
    margin: 1px;
    width: 33%;
  }
}

.mod-mega-menu .list-cate-03 a {
  display: block;
  line-height: 1;
}

@media screen and (min-width: 769px) {
  .mod-mega-menu .list-cate-03 a {
    height: 75px;
    border-radius: 4px;
    padding-top: 32px;
    font-size: 15px;
    font-size: 1.5rem;
    font-weight: 500;
    color: #003399;
    text-align: center;
  }
}

@media screen and (min-width: 769px) {
  .mod-mega-menu .grid-megamenu-nav-04 {
    height: 100%;
    overflow: hidden;
  }
  .mod-mega-menu .grid-megamenu-nav-04 .col {
    float: left;
    height: 100%;
  }
  .mod-mega-menu .grid-megamenu-nav-04 .col:first-child {
    width: 425px;
    margin-right: 20px;
  }
  .mod-mega-menu .grid-megamenu-nav-04 .col:last-child {
    width: 210px;
  }
}

@media screen and (min-width: 769px) {
  .mod-mega-menu .list-cate-04 {
    height: 100%;
    overflow: hidden;
  }
}

@media screen and (min-width: 769px) {
  .mod-mega-menu .list-cate-04 li {
    float: left;
    width: 50%;
    height: 50%;
    border-right: 2px solid #003399;
    border-bottom: 2px solid #003399;
  }
}

.mod-mega-menu .list-cate-04 a {
  display: block;
  line-height: 1;
}

@media screen and (min-width: 769px) {
  .mod-mega-menu .list-cate-04 a {
    height: 100%;
    padding-top: 52px;
    background-color: #fff;
    border: 1px solid #fff;
    font-size: 15px;
    font-size: 1.5rem;
    font-weight: 500;
    color: #003399;
    text-align: center;
  }
  .mod-mega-menu .list-cate-04 a:hover {
    background-color: rgba(255, 255, 255, 0.2);
    color: #ffffff;
  }
}

#g01 .list-nav-global .item:nth-child(2) > a {
  background-color: #003399;
  color: #ffff00;
}

#g01 .nav-global-sp .list-nav-global > li:nth-child(2) > a {
  color: #ffff00;
}

#g01 .nav-global-sp .list-nav-global > li:nth-child(2) > a.has-submenu:after {
  transform: translateY(-50%) rotate(-90deg);
}

#g01 .nav-global-sp .list-nav-global > li:nth-child(2) > div {
  border-top: none;
  display: block;
}

#g02 .list-nav-global .item:nth-child(3) > a {
  background-color: #003399;
  color: #ffff00;
}

#g02 .nav-global-sp .list-nav-global > li:nth-child(3) > a {
  color: #ffff00;
}

#g02 .nav-global-sp .list-nav-global > li:nth-child(3) > a.has-submenu:after {
  transform: translateY(-50%) rotate(-90deg);
}

#g02 .nav-global-sp .list-nav-global > li:nth-child(3) > div {
  border-top: none;
  display: block;
}

#g03 .list-nav-global .item:nth-child(4) > a {
  background-color: #003399;
  color: #ffff00;
}

#g03 .nav-global-sp .list-nav-global > li:nth-child(4) > a {
  color: #ffff00;
}

#g03 .nav-global-sp .list-nav-global > li:nth-child(4) > a.has-submenu:after {
  transform: translateY(-50%) rotate(-90deg);
}

#g03 .nav-global-sp .list-nav-global > li:nth-child(4) > div {
  border-top: none;
  display: block;
}

#g04 .list-nav-global .item:nth-child(5) > a {
  background-color: #003399;
  color: #ffff00;
}

#g04 .nav-global-sp .list-nav-global > li:nth-child(5) > a {
  color: #ffff00;
}

#g04 .nav-global-sp .list-nav-global > li:nth-child(5) > a.has-submenu:after {
  transform: translateY(-50%) rotate(-90deg);
}

#g04 .nav-global-sp .list-nav-global > li:nth-child(5) > div {
  border-top: none;
  display: block;
}

#g05 .list-nav-global .item:nth-child(6) > a {
  background-color: #003399;
  color: #ffff00;
}

#g05 .nav-global-sp .list-nav-global > li:nth-child(6) > a {
  color: #ffff00;
}

#g05 .nav-global-sp .list-nav-global > li:nth-child(6) > a.has-submenu:after {
  transform: translateY(-50%) rotate(-90deg);
}

#g05 .nav-global-sp .list-nav-global > li:nth-child(6) > div {
  border-top: none;
  display: block;
}

/* ==========================================================
 nav-global-sp
========================================================== */
.nav-global-sp-wrapper {
  display: none;
}

@media screen and (max-width: 768px) {
  .nav-global-sp-wrapper {
    position: fixed;
    top: 0;
    left: 57px;
    right: 0;
    bottom: 0;
    z-index: 10;
    display: block;
    background-color: #042142;
    transition: transform 0.2s ease-in;
    transform: translateX(100%);
    backface-visibility: hidden;
  }
  .nav-global-sp-wrapper form {
    margin: 10px;
  }
  .nav-global-sp-wrapper form input {
    padding: 5px;
    border-radius: 4px;
    width: 100%;
  }
  .nav-global-sp-wrapper .nav-global-sp {
    overflow: auto;
    height: 100%;
  }
  .nav-global-sp-wrapper .nav-global-sp .btn-home {
    height: 44px;
    background: url(/inc/image/ico_home_sp.png) center center no-repeat;
    background-size: 14px 11px;
  }
  .nav-global-sp-wrapper .nav-global-sp .ico-blank {
    display: inline-block;
    padding-right: 18px;
    background: url(/inc/image/ico_new_window_02.png) right 3px no-repeat;
    background-size: 12px 12px;
  }
  .nav-global-sp-wrapper .nav-global-sp .ico-blank:after {
    content: none;
    background: none;
  }
  .nav-global-sp-wrapper .list-nav-global > li {
    border-bottom: 1px solid #020f1d;
  }
  .nav-global-sp-wrapper .list-nav-global > li > a {
    position: relative;
    display: block;
    color: #fff;
    padding: 16px 35px;
    font-size: 14px;
    font-size: 1.4rem;
    line-height: 1.4;
    text-align: center;
    text-decoration: none;
  }
  .nav-global-sp-wrapper .list-nav-global > li > a.has-submenu:after {
    position: absolute;
    top: 50%;
    right: 25px;
    width: 6px;
    height: 9px;
    background: url(/inc/image/ico_arrow_left_02.png) center center no-repeat;
    background-size: 6px 9px;
    transform: translateY(-50%);
    content: "";
  }
  .nav-global-sp-wrapper .list-nav-global > li > a.has-submenu.is-open:after {
    transform: translateY(-50%) rotate(-90deg);
  }
  .nav-global-sp-wrapper .box-nav-sub {
    display: none;
    background-color: #03152b;
  }
  .nav-global-sp-wrapper .box-nav-sub .list-nav-sub li a {
    position: relative;
    display: block;
    padding: 14px 20px 14px 40px;
    color: #fff;
    font-size: 14px;
    font-size: 1.4rem;
    line-height: 1.4;
  }
  .nav-global-sp-wrapper .box-nav-sub .list-nav-sub li a:before {
    position: absolute;
    top: 50%;
    left: 24px;
    width: 7px;
    height: 1px;
    margin-top: -1px;
    background-color: #ffff00;
    content: "";
  }
  .nav-global-sp-wrapper .box-nav-sub .list-nav-sub li a span {
    display: inline-block;
    text-align: center;
  }
  .nav-global-sp-wrapper .box-nav-sub .list-nav-sub li a.is-active {
    color: #fff000;
  }
}

/* ==========================================================
 nav-local
========================================================== */
/* nav-local-01
---------------------------------------------------------- */
.nav-local-01 {
  z-index: 999;
  left: 0;
  top: 0;
  width: 100%;
  border-top: 1px dotted #ccc;
  border-bottom: 1px dotted #ccc;
  position: relative;
}

.nav-local-01 .heading {
  display: none;
}

.nav-local-01 .inner-wrapper-sticky {
  transition: all .5s;
}

.nav-local-01.is-affixed {
  border: none;
}

.nav-local-01.is-affixed .inner-wrapper-sticky {
  background-color: #f2f2f2;
  width: 100% !important;
  left: 0 !important;
  box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.16);
}

.nav-local-01.is-affixed .inner-wrapper-sticky > ul {
  margin: 0 auto;
}

.nav-local-01 ul {
  font-size: .9em;
  width: 1000px;
  margin: 20px auto;
  text-align: center;
  letter-spacing: -0.3em;
  transition: all .5s;
}

.nav-local-01 ul > li {
  letter-spacing: normal;
  display: inline-block;
  padding-left: 2px;
  padding-right: 2px;
}

.nav-local-01 ul > li:after {
  content: "";
  display: inline-block;
  border-right: 1px solid #ccc;
  height: 1em;
}

.nav-local-01 ul > li:last-child:after {
  display: none;
}

.nav-local-01 ul > li > a {
  padding: 10px;
  position: relative;
  white-space: nowrap;
  display: inline-block;
  line-height: 1em;
  font-weight: 500;
  color: #222;
  transition: background-color .3s;
  border-radius: 4px;
}

.nav-local-01 ul > li > a:hover {
  background-color: #f6f6f6;
}

.fixedSubNavi .nav-local-01 ul > li > a:hover {
  border-radius: 0;
  background-color: #e6e6e6;
}

.nav-local-01 ul > li > a.is-active {
  font-weight: 600;
  color: #003399;
}

.nav-local-01 ul > li > a.is-active:after {
  filter: grayscale(0%) brightness(100%) !important;
}

.l01 .nav-local-01 > ul > li:nth-child(1) > a {
  color: #003399;
}

.l01 .nav-local-01 > ul > li:nth-child(1) > a:before {
  display: block;
}

.l02 .nav-local-01 > ul > li:nth-child(2) > a {
  color: #003399;
}

.l02 .nav-local-01 > ul > li:nth-child(2) > a:before {
  display: block;
}

.l03 .nav-local-01 > ul > li:nth-child(3) > a {
  color: #003399;
}

.l03 .nav-local-01 > ul > li:nth-child(3) > a:before {
  display: block;
}

.l04 .nav-local-01 > ul > li:nth-child(4) > a {
  color: #003399;
}

.l04 .nav-local-01 > ul > li:nth-child(4) > a:before {
  display: block;
}

.l05 .nav-local-01 > ul > li:nth-child(5) > a {
  color: #003399;
}

.l05 .nav-local-01 > ul > li:nth-child(5) > a:before {
  display: block;
}

.l06 .nav-local-01 > ul > li:nth-child(6) > a {
  color: #003399;
}

.l06 .nav-local-01 > ul > li:nth-child(6) > a:before {
  display: block;
}

.l07 .nav-local-01 > ul > li:nth-child(7) > a {
  color: #003399;
}

.l07 .nav-local-01 > ul > li:nth-child(7) > a:before {
  display: block;
}

.l08 .nav-local-01 > ul > li:nth-child(8) > a {
  color: #003399;
}

.l08 .nav-local-01 > ul > li:nth-child(8) > a:before {
  display: block;
}

.l09 .nav-local-01 > ul > li:nth-child(9) > a {
  color: #003399;
}

.l09 .nav-local-01 > ul > li:nth-child(9) > a:before {
  display: block;
}

.l010 .nav-local-01 > ul > li:nth-child(10) > a {
  color: #003399;
}

.l010 .nav-local-01 > ul > li:nth-child(10) > a:before {
  display: block;
}

.l011 .nav-local-01 > ul > li:nth-child(11) > a {
  color: #003399;
}

.l011 .nav-local-01 > ul > li:nth-child(11) > a:before {
  display: block;
}

.l012 .nav-local-01 > ul > li:nth-child(12) > a {
  color: #003399;
}

.l012 .nav-local-01 > ul > li:nth-child(12) > a:before {
  display: block;
}

.l013 .nav-local-01 > ul > li:nth-child(13) > a {
  color: #003399;
}

.l013 .nav-local-01 > ul > li:nth-child(13) > a:before {
  display: block;
}

.l014 .nav-local-01 > ul > li:nth-child(14) > a {
  color: #003399;
}

.l014 .nav-local-01 > ul > li:nth-child(14) > a:before {
  display: block;
}

.l015 .nav-local-01 > ul > li:nth-child(15) > a {
  color: #003399;
}

.l015 .nav-local-01 > ul > li:nth-child(15) > a:before {
  display: block;
}

.l016 .nav-local-01 > ul > li:nth-child(16) > a {
  color: #003399;
}

.l016 .nav-local-01 > ul > li:nth-child(16) > a:before {
  display: block;
}

.l017 .nav-local-01 > ul > li:nth-child(17) > a {
  color: #003399;
}

.l017 .nav-local-01 > ul > li:nth-child(17) > a:before {
  display: block;
}

.l018 .nav-local-01 > ul > li:nth-child(18) > a {
  color: #003399;
}

.l018 .nav-local-01 > ul > li:nth-child(18) > a:before {
  display: block;
}

.l019 .nav-local-01 > ul > li:nth-child(19) > a {
  color: #003399;
}

.l019 .nav-local-01 > ul > li:nth-child(19) > a:before {
  display: block;
}

.l020 .nav-local-01 > ul > li:nth-child(20) > a {
  color: #003399;
}

.l020 .nav-local-01 > ul > li:nth-child(20) > a:before {
  display: block;
}

/* nav-local-sub

---------------------------------------------------------- */
.nav-local-01 ul > li > a.has-sub {
  position: relative;
  padding-right: 30px;
  border-radius: 3px;
}

.nav-local-01 ul > li > a.has-sub:after {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  right: 9px;
  z-index: 1;
  width: 14px;
  height: 14px;
  background: url(/inc/image/ico_link_arrow_01_off.png) no-repeat;
  background-size: 14px 14px;
  transform: translateY(-50%) rotate(-180deg);
  filter: grayscale(100%) brightness(200%);
}

.nav-local-01 ul > li > a.has-sub.is-open {
  box-shadow: 0px 2px 10px -2px rgba(0, 0, 0, 0.129);
  background-color: #003399;
  border-radius: 4px 4px 0 0;
  color: #fff000;
}

.nav-local-01 ul > li > a.has-sub.is-open:after {
  transform: translateY(-50%) rotate(-270deg);
  filter: grayscale(0%) brightness(100%);
}

.nav-local-01 .nav-local-sub {
  display: none;
  position: absolute;
  z-index: 100;
  background-color: #003399;
  text-align: left;
  border-radius: 0 4px 4px 4px;
  box-shadow: 0px 5px 10px -2px rgba(0, 0, 0, 0.129);
}

.nav-local-01 .nav-local-sub ul {
  width: auto;
  margin-top: 10px;
  margin-bottom: 10px;
}

.nav-local-01 .nav-local-sub ul li {
  display: block;
  text-align: left;
}

.nav-local-01 .nav-local-sub ul li:after {
  display: none;
}

.nav-local-01 .nav-local-sub ul li a {
  color: #fff;
  position: relative;
  display: block;
  padding: 7px 25px;
  transition: background-color .3s;
}

.nav-local-01 .nav-local-sub ul li a:hover, .nav-local-01 .nav-local-sub ul li a.is-active {
  background-color: #003aad;
  color: #fff000;
}

/* ==========================================================
 nav-breadcrumb
========================================================== */
.nav-breadcrumb {
  overflow: hidden;
  padding: 10px 0;
  font-size: 11px;
  font-size: 1.2rem;
  font-weight: 100;
}

@media screen and (max-width: 768px) {
  .nav-breadcrumb {
    font-size: 12px;
    font-size: 1.2rem;
  }
}

.nav-breadcrumb ul li {
  float: left;
}

.nav-breadcrumb ul li:after {
  padding: 0 7px;
  content: "/";
}

.nav-breadcrumb ul li:last-child:after {
  display: none;
}

.nav-breadcrumb ul li a:hover {
  text-decoration: underline;
}

@media screen and (max-width: 768px) {
  .nav-breadcrumb ul {
    overflow: auto;
    width: 100%;
    white-space: nowrap;
  }
  .nav-breadcrumb ul li {
    float: none;
    display: inline-block;
  }
}

/* ==========================================================
 card
========================================================== */
/* card-cmn-01
---------------------------------------------------------- */
.card-cmn-01, .card-cmn-02 {
  text-align: center;
}

.card-cmn-01 a, .card-cmn-02 a {
  transition: box-shadow 0.25s;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
}

.card-cmn-01 a:hover, .card-cmn-02 a:hover {
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2);
  position: relative;
  z-index: 10;
}

.card-cmn-01 .image, .card-cmn-02 .image {
  overflow: hidden;
  display: block;
}

.card-cmn-01 .image img, .card-cmn-02 .image img {
  width: 100%;
  transition: transform 0.2s ease-in;
}

@media screen and (min-width: 769px) {
  .card-cmn-01 .image img, .card-cmn-02 .image img {
    width: 100%;
  }
  .card-cmn-01 .image:hover img, .card-cmn-02 .image:hover img {
    transform: scale(1.04);
  }
}

.card-cmn-01 .title, .card-cmn-02 .title {
  margin: 10px 0;
  font-weight: 500;
}

@media screen and (max-width: 768px) {
  .card-cmn-01 .title, .card-cmn-02 .title {
    font-size: 14px;
    font-size: 1.4rem;
  }
}

.card-cmn-01 .txt-code, .card-cmn-02 .txt-code {
  color: #5b5b5b;
  font-size: 13px;
  font-size: 1.3rem;
  margin-top: -10px;
}

.card-cmn-01 .txt-info, .card-cmn-02 .txt-info {
  font-size: 13px;
  font-size: 1.3rem;
}

@media screen and (max-width: 768px) {
  .card-cmn-01 .txt-info, .card-cmn-02 .txt-info {
    font-size: 11px;
    font-size: 1.1rem;
  }
}

/* card-cmn-02
---------------------------------------------------------- */
.card-cmn-02 .title {
  margin-bottom: 0;
  color: #003399;
}

.card-cmn-02 .txt-info {
  font-size: 14px;
  font-size: 1.4rem;
  text-align: left;
}

@media screen and (max-width: 768px) {
  .card-cmn-02 .txt-info {
    font-size: 12px;
    font-size: 1.2rem;
  }
}

/* ==========================================================
 hero
========================================================== */
/* hero-page-title
---------------------------------------------------------- */
.hero-page-title {
  width: 100%;
  margin-bottom: 50px;
  background-repeat: no-repeat;
  background-position: right center;
  background-size: 800px;
  background-color: #E7E7E7;
}

@media screen and (max-width: 768px) {
  .hero-page-title {
    background-size: 500px;
  }
}

#g01 .hero-page-title,
#g00 .hero-page-title {
  background-image: url(../image/bg_hero_about.jpg);
}

#g02 .hero-page-title {
  background-image: url(../image/bg_hero_products.jpg);
}

#g03 .hero-page-title {
  background-image: url(../image/bg_hero_effort.jpg);
}

#g04 .hero-page-title {
  background-image: url(../image/bg_hero_news.jpg);
}

#g05 .hero-page-title {
  background-image: url(../image/bg_hero_global.jpg);
}

@media screen and (max-width: 768px) {
  .hero-page-title {
    background-position: 0px 0px;
    background-attachment: scroll;
    margin-bottom: 30px;
  }
}

.hero-page-title .nav-breadcrumb {
  position: absolute;
  top: 0;
  left: 20px;
  right: 20px;
  color: #666;
}

.hero-page-title .nav-breadcrumb a {
  color: #666;
}

@media screen and (max-width: 768px) {
  .hero-page-title .nav-breadcrumb {
    left: 15px;
    right: 15px;
  }
}

.hero-page-title .hero-page-title-in {
  position: relative;
  width: 100%;
  min-height: 145px !important;
  margin: 0 auto;
}

@media screen and (max-width: 768px) {
  .hero-page-title .hero-page-title-in {
    min-height: 130px;
  }
}

.hero-page-title .box-inner {
  position: absolute;
  width: 100%;
  top: 50%;
  transform: translateY(-50%);
}

@media screen and (max-width: 768px) {
  .hero-page-title .box-inner {
    padding: 15px;
    font-size: 22px;
    font-size: 2.2rem;
  }
}

.hero-page-title .box-inner .title {
  position: relative;
  font-size: 34px;
  font-size: 3.4rem;
  font-weight: 200;
  width: 1000px;
  margin: auto;
  color: #002b80;
}

@media screen and (max-width: 768px) {
  .hero-page-title .box-inner .title {
    font-size: 25px;
    font-size: 2.5rem;
    width: 100%;
  }
}

.hero-page-title.type-no-bg {
  background: none;
  border-top: 1px solid #e5e5e5;
  border-bottom: 1px solid #c1c1c1;
}

.hero-page-title.type-no-bg .box-inner .title {
  color: #003399;
  font-weight: 500;
}

.hero-page-title.type-no-bg .nav-breadcrumb {
  color: #393939;
}

.hero-page-title.type-no-bg .nav-breadcrumb a {
  color: #393939;
}

/* box-contact-link-btm
---------------------------------------------------------- */
.box-contact-link {
  background: #424a58;
}

.box-contact-link .box-contact-link-in {
  overflow: hidden;
  width: 892px;
  margin: 0 auto;
  padding: 36px 0 25px;
}

@media screen and (max-width: 768px) {
  .box-contact-link .box-contact-link-in {
    width: auto;
    padding: 0;
  }
}

.box-contact-link .box-item {
  width: 50%;
  color: #fff;
  margin: auto;
}

@media screen and (max-width: 768px) {
  .box-contact-link .box-item {
    float: none;
    width: auto;
    padding: 20px 10px;
  }
  .box-contact-link .box-item:last-child {
    border-left: none;
    border-top: 1px solid #868b94;
  }
}

.box-contact-link .box-item .title {
  margin-bottom: 12px;
  color: #fff;
  font-size: 22px;
  font-size: 2.2rem;
  font-weight: 500;
  line-height: 1;
  text-align: center;
}

@media screen and (max-width: 768px) {
  .box-contact-link .box-item .title {
    font-size: 18px;
    font-size: 1.8rem;
  }
}

.box-contact-link .box-item .text {
  min-height: 60px;
  line-height: 1.4;
}

@media screen and (max-width: 768px) {
  .box-contact-link .box-item .text {
    min-height: 0;
    margin-bottom: 15px;
  }
}

.box-contact-link .box-item .btn-normal-02 {
  width: auto;
}

/* ==========================================================
 title
========================================================== */
/* ttl-cmn-01
---------------------------------------------------------- */
.ttl-cmn-01 {
  position: relative;
  margin-top: 5px;
  margin-bottom: 20px;
  padding-bottom: 15px;
  font-size: 30px;
  padding-left: 3px;
  font-size: 2.7rem;
  font-weight: 200;
}

.ttl-cmn-01:before, .ttl-cmn-01:after {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 3px;
  background: #e6e6e6;
  content: "";
}

.ttl-cmn-01:after {
  width: 90px;
  background: #003399;
}

@media screen and (max-width: 768px) {
  .ttl-cmn-01 {
    margin-bottom: 15px;
    font-size: 18px;
    font-size: 1.8rem;
  }
}

/* ttl-cmn-02
---------------------------------------------------------- */
.ttl-cmn-02 {
  position: relative;
  margin-top: 10px;
  margin-bottom: 10px;
  padding-left: 17px;
  font-size: 21px;
  font-size: 2.1rem;
  font-weight: 400;
}

.ttl-cmn-02.no-dot:before {
  display: none;
}

.ttl-cmn-02:before {
  position: absolute;
  top: 11px;
  left: 0;
  width: 10px;
  height: 10px;
  background: #003399;
  border-radius: 50%;
  content: "";
}

@media screen and (max-width: 768px) {
  .ttl-cmn-02 {
    margin-bottom: 15px;
    padding-left: 18px;
    font-size: 15px;
    font-size: 1.5rem;
  }
  .ttl-cmn-02:before {
    top: 7px;
    width: 10px;
    height: 10px;
  }
}

.policy .ttl-cmn-02 {
  padding-left: 0;
}

.policy .ttl-cmn-02:before {
  content: none;
}

/* ttl-cmn-03
---------------------------------------------------------- */
.ttl-cmn-03 {
  margin-bottom: 50px;
  font-size: 22px;
  font-size: 2.2rem;
  font-weight: 300;
  text-align: center;
}

@media screen and (max-width: 768px) {
  .ttl-cmn-03 {
    margin-bottom: 30px;
    font-size: 18px;
    font-size: 1.8rem;
  }
}

.ttl-cmn-03 span {
  position: relative;
  display: inline-block;
  padding-bottom: 15px;
}

.ttl-cmn-03 span:before, .ttl-cmn-03 span:after {
  position: absolute;
  bottom: 0;
  left: 50%;
  z-index: 0;
  width: 90px;
  height: 3px;
  margin-left: -45px;
  background: #fff100;
  content: "";
}

.ttl-cmn-03 span:after {
  z-index: 1;
  width: 77px;
  background: #003399;
}

/* ==========================================================
 box
========================================================== */
/* box-cmn-01
---------------------------------------------------------- */
.box-cmn-01 a.box-item:hover {
  background-color: #fff;
  border: 1px solid #cdcdcd;
}

.box-cmn-01 .box-item {
  overflow: hidden;
  display: block;
  padding: 15px;
  transition: background-color .3s;
  background-color: #f3f3f3;
  border: 1px solid #f3f3f3;
  margin-bottom: 2px;
}

.box-cmn-01 .box-item .image {
  float: left;
  width: 190px;
}

.box-cmn-01 .box-item .image img {
  width: 100%;
}

@media screen and (max-width: 768px) {
  .box-cmn-01 .box-item .image {
    float: none;
    width: 100%;
  }
}

.box-cmn-01 .box-item .box-detail {
  overflow: hidden;
  padding-left: 15px;
}

.box-cmn-01 .box-item .box-detail .title {
  margin-bottom: 8px;
  color: #003399;
  font-size: 18px;
  font-size: 1.8rem;
  line-height: 1.3em;
}

@media screen and (max-width: 768px) {
  .box-cmn-01 .box-item .box-detail .title {
    font-size: 15px;
    font-size: 1.5rem;
  }
}

.box-cmn-01.txt-center .box-item .image {
  display: table-cell;
  float: none;
  vertical-align: top;
}

@media screen and (max-width: 768px) {
  .box-cmn-01.txt-center .box-item .image {
    display: block;
  }
}

.box-cmn-01.txt-center .box-item .box-detail {
  display: table-cell;
  vertical-align: middle;
}

@media screen and (max-width: 768px) {
  .box-cmn-01.txt-center .box-item .box-detail {
    display: block;
    padding: 10px 0 0;
  }
}

/* box-cmn-02
---------------------------------------------------------- */
.box-cmn-02 {
  padding: 20px;
  background: #f2f2f2;
  line-height: 2;
}

.box-cmn-02 .title {
  font-weight: 700;
  font-size: 15px;
  font-size: 1.5rem;
  color: #003399;
  line-height: 1.7;
}

@media screen and (max-width: 768px) {
  .box-cmn-02 .title {
    font-size: 13px;
    font-size: 1.3rem;
  }
}

.box-cmn-02 p:not(:last-child) {
  margin-bottom: 20px;
}

/* box-text-img-01
---------------------------------------------------------- */
.box-text-img-01 {
  overflow: hidden;
  padding: 20px;
  border: 1px solid #cdcdcd;
}

.box-text-img-01 .image {
  float: right;
  width: 157px;
}

.box-text-img-01 .image img {
  width: 100%;
}

@media screen and (max-width: 768px) {
  .box-text-img-01 .image {
    float: none;
    width: 100%;
    margin-bottom: 15px;
  }
}

.box-text-img-01 .box-detail {
  overflow: hidden;
  padding-right: 20px;
}

@media screen and (max-width: 768px) {
  .box-text-img-01 .box-detail {
    padding-right: 0;
  }
}

/* box-text-img-02
---------------------------------------------------------- */
.box-text-img-02 {
  overflow: hidden;
}

@media screen and (max-width: 768px) {
  .box-text-img-02 {
    display: flex;
    flex-wrap: wrap;
  }
}

.box-text-img-02 .image {
  float: right;
  width: 240px;
}

.box-text-img-02 .image img {
  width: 100%;
}

@media screen and (max-width: 768px) {
  .box-text-img-02 .image {
    float: none;
    width: 100%;
    order: 1;
  }
}

.box-text-img-02 .box-text {
  overflow: hidden;
  padding-right: 20px;
}

@media screen and (max-width: 768px) {
  .box-text-img-02 .box-text {
    margin-bottom: 10px;
    padding-right: 0;
    order: 0;
  }
}

/* box-hero-text
---------------------------------------------------------- */
.box-hero-text {
  position: relative;
  display: table;
  width: 100%;
  padding: 1px;
  border: 1px solid #003399;
  border-radius: 4px;
}

.box-hero-text .box-hero-text-in {
  display: table-cell;
  width: 100%;
  height: 170px;
  padding: 20px;
  border: 1px solid #003399;
  border-radius: 4px;
  font-family: serif;
  font-size: 24px;
  font-size: 2.4rem;
  text-align: center;
  vertical-align: middle;
}

@media screen and (max-width: 768px) {
  .box-hero-text .box-hero-text-in {
    height: 120px;
    padding: 20px 15px;
    font-size: 18px;
    font-size: 1.8rem;
  }
}

.box-hero-text.txt-small .box-hero-text-in {
  font-size: 17px;
  font-size: 1.7rem;
}

@media screen and (max-width: 768px) {
  .box-hero-text.txt-small .box-hero-text-in {
    font-size: 15px;
    font-size: 1.5rem;
  }
}

/* box-table-layout-01
---------------------------------------------------------- */
.box-table-layout-01 {
  border-top: 1px solid #cdcdcd;
  width: 100%;
}

.box-table-layout-01 .box-item {
  display: table;
  width: 100%;
}

.box-table-layout-01 .box-item:nth-child(even) {
  background-color: #fbfbfb;
}

@media screen and (max-width: 768px) {
  .box-table-layout-01 .box-item {
    table-layout: fixed;
  }
}

.box-table-layout-01 .box-head {
  display: table-cell;
  width: 160px;
  padding: 15px;
  background: #f5f5f5;
  border-bottom: 1px solid #cdcdcd;
  font-weight: 700;
  text-align: center;
}

@media screen and (max-width: 768px) {
  .box-table-layout-01 .box-head {
    width: 120px;
  }
}

.box-table-layout-01 .box-detail {
  display: table-cell;
  border-bottom: 1px solid #cdcdcd;
  padding: 15px;
  text-align: left;
  vertical-align: top;
}

@media screen and (max-width: 768px) {
  .box-table-layout-01 .box-detail {
    padding: 8px;
  }
}

/* box-table-wrapper
---------------------------------------------------------- */
@media screen and (max-width: 768px) {
  .box-table-wrapper {
    overflow: auto;
  }
  .scrollable-table-wrapper {
    margin-top: 25px;
    overflow: auto;
    border: 2px solid #eeee01;
  }
  .scrollable-table-wrapper::before {
    content: "横にスクロールができます ◀︎▶︎";
    display: inline-block;
    position: absolute;
    height: 25px;
    line-height: 25px;
    background-color: #eeee00;
    padding: 0px 5px;
    border-radius: 3px 3px 0px 0px;
    color: #314f8c;
    margin-top: -25px;
  }
}

/* box-news-highlight
---------------------------------------------------------- */
.box-news-highlight {
  display: table;
  width: 100%;
  margin-bottom: 30px;
  background: #fff54a;
  text-align: center;
  border-radius: 5px;
}

@media screen and (max-width: 768px) {
  .box-news-highlight {
    display: flex;
    flex-wrap: wrap;
  }
}

.box-news-highlight .box-news-txt-wrap {
  width: 100%;
}

.box-news-highlight .txt-title {
  display: table-cell;
  width: 143px;
  padding: 10px 0;
  background: #ffe800;
  border-radius: 5px 0 0 5px;
  vertical-align: middle;
}

@media screen and (max-width: 768px) {
  .box-news-highlight .txt-title {
    display: block;
    width: 100%;
    padding: 6px 0;
  }
}

.box-news-highlight .txt-date {
  display: table-cell;
  width: 110px;
  padding: 10px 0;
}

@media screen and (max-width: 768px) {
  .box-news-highlight .txt-date {
    display: block;
    width: 100%;
    padding: 10px 15px 0;
    line-height: 1;
    text-align: left;
  }
}

.box-news-highlight .txt-detail {
  display: table-cell;
  padding: 10px 0;
  text-align: left;
}

.box-news-highlight .txt-detail a:hover {
  text-decoration: underline;
}

@media screen and (max-width: 768px) {
  .box-news-highlight .txt-detail {
    display: block;
    width: 100%;
    padding: 0 15px 5px;
  }
}

/* box-history-01
---------------------------------------------------------- */
.box-history-01 {
  position: relative;
  padding-bottom: 50px;
}

@media screen and (max-width: 768px) {
  .box-history-01 {
    padding-bottom: 20px;
  }
}

.box-history-01:before {
  position: absolute;
  top: 30px;
  left: 160px;
  bottom: 0;
  width: 1px;
  background-color: #d2d2e7;
  content: "";
}

@media screen and (max-width: 768px) {
  .box-history-01:before {
    top: 15px;
    left: 92px;
  }
}

.box-history-01 > .box-item {
  display: table;
  width: 100%;
}

.box-history-01 > .box-item:nth-child(even) {
  background-color: #f7f7f7;
}

.box-history-01 > .box-item .box-date {
  display: table-cell;
  padding: 20px 0;
  width: 160px;
  text-align: center;
  vertical-align: top;
}

@media screen and (max-width: 768px) {
  .box-history-01 > .box-item .box-date {
    padding: 10px 0;
    width: 90px;
  }
}

.box-history-01 > .box-item .box-date .ttl-main {
  font-size: 20px;
  font-size: 2rem;
  font-weight: 700;
  line-height: 1.1;
}

@media screen and (max-width: 768px) {
  .box-history-01 > .box-item .box-date .ttl-main {
    font-size: 16px;
    font-size: 1.6rem;
  }
}

.box-history-01 > .box-item .box-date .ttl-sub {
  font-size: 14px;
  font-size: 1.4rem;
}

@media screen and (max-width: 768px) {
  .box-history-01 > .box-item .box-date .ttl-sub {
    font-size: 12px;
    font-size: 1.2rem;
  }
}

.box-history-01 > .box-item .box-info {
  position: relative;
  display: table-cell;
  padding: 18px 0 20px 45px;
  vertical-align: top;
}

@media screen and (max-width: 768px) {
  .box-history-01 > .box-item .box-info {
    padding: 7px 0 10px 25px;
  }
}

.box-history-01 > .box-item .box-info:before {
  position: absolute;
  top: 25px;
  left: -4px;
  width: 10px;
  height: 10px;
  background: #003399;
  border-radius: 10px;
  content: "";
}

@media screen and (max-width: 768px) {
  .box-history-01 > .box-item .box-info:before {
    top: 14px;
    left: 0;
    width: 6px;
    height: 6px;
  }
}

/* box-accordion-01
---------------------------------------------------------- */
.box-accordion-01 > .box-item .box-question {
  position: relative;
  padding: 16px 15px 16px 48px;
  background-color: #f2f2f2;
  cursor: pointer;
  -webkit-transition: opacity 0.15s ease 0s;
  transition: opacity 0.15s ease 0s;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.box-accordion-01 > .box-item .box-question:hover {
  opacity: 0.7;
}

@media screen and (max-width: 768px) {
  .box-accordion-01 > .box-item .box-question:hover {
    opacity: 1;
  }
}

.box-accordion-01 > .box-item .box-question:before, .box-accordion-01 > .box-item .box-question.is-open:before {
  position: absolute;
  top: 15px;
  left: 12px;
  width: 25px;
  height: 25px;
  background: url(/inc/image/ico_plus.png) no-repeat;
  background-size: 25px 25px;
  content: "";
}

.box-accordion-01 > .box-item .box-question.is-open {
  background: #003399 !important;
  color: #fff;
}

.box-accordion-01 > .box-item .box-question.is-open:before {
  background: url(/inc/image/ico_minus.png) no-repeat;
  background-size: 25px 25px;
}

.box-accordion-01 > .box-item:nth-child(even) .box-question {
  background-color: #fff;
}

.box-accordion-01 > .box-item .box-answer {
  display: none;
  padding: 15px 20px 25px;
  background-color: #f0f5fb;
  line-height: 2;
}

/* ==========================================================
 module
========================================================== */
/* tab
---------------------------------------------------------- */
.mod-tab-01 {
  overflow: hidden;
}

.mod-tab-01 .list-tab-01 {
  margin-bottom: 40px;
}

.mod-tab-01 .tab-content {
  position: relative;
  min-height: 300px;
  padding-top: 40px;
}

@media screen and (max-width: 768px) {
  .mod-tab-01 .tab-content {
    padding-top: 20px;
  }
}

.mod-tab-01 .tab-content .tab {
  display: none;
}

.list-tab-nav-01 {
  background-color: #dadada;
  overflow: hidden;
  border-radius: 5px 5px 0 0;
}

@media screen and (min-width: 769px) {
  .list-tab-nav-01.is-affixed {
    z-index: 1000;
  }
  .list-tab-nav-01.is-affixed .inner-wrapper-sticky {
    background-color: #eee !important;
  }
}

@media screen and (max-width: 768px) {
  .list-tab-nav-01 {
    border: 1px solid #aaa;
    border-radius: 5px;
  }
}

@media screen and (max-width: 768px) {
  .list-tab-nav-01.col-2-sp li, .list-tab-nav-01.col-3-sp li {
    width: 100%;
  }
}

.list-tab-nav-01.col-2-sp a, .list-tab-nav-01.col-3-sp a {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.list-tab-nav-01.pulled a {
  border-bottom: none;
}

.list-tab-nav-01 li {
  float: left;
  line-height: 1;
  min-width: 100px;
}

@media screen and (max-width: 768px) {
  .list-tab-nav-01 li:last-child a {
    border: none;
  }
}

.list-tab-nav-01 a {
  position: relative;
  display: inline-block;
  padding: 18px 15px;
  background-color: #354d7c;
  border-top: 1px solid transparent;
  border-left: 1px solid transparent;
  border-right: 1px solid rgba(255, 255, 255, 0.17);
  color: #fff;
  transition: color .6s, background-color .6s;
}

@media screen and (max-width: 768px) {
  .list-tab-nav-01 a {
    background-color: #fff;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 1px dotted #ccc;
    color: #444;
  }
}

.list-tab-nav-01 a:hover {
  color: #fff;
}

.list-tab-nav-01 a:before {
  display: none;
  content: "";
  position: absolute;
  left: 50%;
  bottom: 3px;
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 10px solid #dec100;
  transform: translateX(-50%);
}

@media screen and (max-width: 768px) {
  .list-tab-nav-01 a:before {
    right: 10px;
    left: auto;
    top: 50%;
    transform: translateY(-50%);
  }
}

.list-tab-nav-01 a.is-active {
  background-color: #fff;
  border-top-color: #c1c1c1;
  border-right-color: #c1c1c1;
  border-left-color: #c1c1c1;
  color: #003399;
  font-weight: 500;
}

.list-tab-nav-01 a.is-active:before {
  display: block;
}

@media screen and (max-width: 768px) {
  .list-tab-nav-01 a.is-active {
    background-color: #354d7c;
    color: #fff;
  }
}

@media screen and (min-width: 769px) {
  .list-tab-nav-01 a:not(.is-active) {
    -webkit-transition: opacity 0.15s ease 0s;
    transition: opacity 0.15s ease 0s;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }
  .list-tab-nav-01 a:not(.is-active):hover {
    opacity: 0.7;
  }
}

@media screen and (min-width: 769px) and (max-width: 768px) {
  .list-tab-nav-01 a:not(.is-active):hover {
    opacity: 1;
  }
}

@media screen and (max-width: 768px) {
  .list-tab-nav-01 a {
    padding: 15px 5px;
    font-size: 13px;
  }
}

/* ==========================================================
 table
========================================================== */
/* table-cmn-01
---------------------------------------------------------- */
.table-cmn-01,
.article-product .tab table,
.box-news-detail-content table {
  width: 100%;
}

@media screen and (max-width: 768px) {
  .table-cmn-01.full,
  .article-product .tab table.full,
  .box-news-detail-content table.full {
    width: 100%;
  }
}

.table-cmn-01 th,
.article-product .tab table th,
.box-news-detail-content table th {
  padding: 11px 15px;
  background: #f5f5f5;
  border-right: 1px dashed #c4c4c4;
  border-bottom: 1px solid #cdcdcd;
  font-weight: 700;
  text-align: center;
}

.table-cmn-01 th:last-child,
.article-product .tab table th:last-child,
.box-news-detail-content table th:last-child {
  border-right: none;
}

.table-cmn-01 td,
.article-product .tab table td,
.box-news-detail-content table td {
  padding: 11px 15px;
  border-right: 1px dashed #c4c4c4;
  border-bottom: 1px solid #cdcdcd;
}

.table-cmn-01 td:last-child,
.article-product .tab table td:last-child,
.box-news-detail-content table td:last-child {
  border-right: none;
}

.table-cmn-01 tr.color-01,
.article-product .tab table tr.color-01,
.box-news-detail-content table tr.color-01 {
  background: #ffffcb;
  color: #c14900;
}

table .scrollable-table-wrapper {
  margin-top: 25px;
}

table.no_border td {
  border: none;
}

/* ==========================================================
 button
========================================================== */
/* btn-normal-01
---------------------------------------------------------- */
.btn-normal-01, .btn-normal-02, .btn-normal-03,
.btn-small-01, .btn-small-02 {
  background-color: #fff;
  overflow: hidden;
  position: relative;
  display: block;
  width: 380px;
  margin: 0 auto;
  padding: 10px 0;
  border: 2px solid #002e8a;
  border-radius: 5px;
  font-weight: 500;
  color: #223968;
  text-align: center;
  transform: translateZ(0);
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  transition: box-shadow 0.2s;
}

.btn-normal-01.inline, .btn-normal-02.inline, .btn-normal-03.inline,
.btn-small-01.inline, .btn-small-02.inline {
  width: auto;
  display: inline-block;
  margin-right: 4px;
  padding-left: 10px;
  padding-right: 28px;
}

@media screen and (max-width: 768px) {
  .btn-normal-01.inline, .btn-normal-02.inline, .btn-normal-03.inline,
  .btn-small-01.inline, .btn-small-02.inline {
    width: 100%;
  }
}

.btn-normal-01.full, .btn-normal-02.full, .btn-normal-03.full,
.btn-small-01.full, .btn-small-02.full {
  width: 100%;
}

@media screen and (max-width: 768px) {
  .btn-normal-01, .btn-normal-02, .btn-normal-03,
  .btn-small-01, .btn-small-02 {
    width: 100%;
    font-size: 14px;
    font-size: 1.4rem;
  }
}

.btn-normal-01 span, .btn-normal-02 span, .btn-normal-03 span,
.btn-small-01 span, .btn-small-02 span {
  position: relative;
  z-index: 1;
  display: inline-block;
}

.btn-normal-01:after, .btn-normal-02:after, .btn-normal-03:after,
.btn-small-01:after, .btn-small-02:after {
  position: absolute;
  top: 50%;
  right: 8px;
  width: 14px;
  height: 14px;
  margin-top: -7px;
  background: url(/inc/image/ico_link_arrow_01_off.png) no-repeat;
  background-size: 14px 14px;
  content: "";
}

.btn-normal-01:before, .btn-normal-02:before, .btn-normal-03:before,
.btn-small-01:before, .btn-small-02:before {
  position: absolute;
  height: 0;
  left: 50%;
  top: 50%;
  width: 101%;
  z-index: -1;
  transition: opacity 0.2s ease, height 0.2s ease;
  background-color: #002e8a;
  opacity: 0.5;
  content: "";
}

@media screen and (max-width: 768px) {
  .btn-normal-01:before, .btn-normal-02:before, .btn-normal-03:before,
  .btn-small-01:before, .btn-small-02:before {
    display: none;
  }
}

@media screen and (min-width: 769px) {
  .btn-normal-01:hover, .btn-normal-02:hover, .btn-normal-03:hover,
  .btn-small-01:hover, .btn-small-02:hover {
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2);
    color: #fff;
  }
  .btn-normal-01:hover:before, .btn-normal-02:hover:before, .btn-normal-03:hover:before,
  .btn-small-01:hover:before, .btn-small-02:hover:before {
    opacity: 1;
    height: 105%;
  }
  .btn-normal-01:hover:after, .btn-normal-02:hover:after, .btn-normal-03:hover:after,
  .btn-small-01:hover:after, .btn-small-02:hover:after {
    background: url(/inc/image/ico_link_arrow_01_on.png) no-repeat;
    background-size: 14px 14px;
  }
}

:root .btn-normal-01:before, :root .btn-normal-02:before, :root .btn-normal-03:before,
:root .btn-small-01:before, :root .btn-small-02:before {
  transform: translateY(-50%) translateX(-50%);
}

/* btn-normal-02
---------------------------------------------------------- */
.btn-normal-02 {
  background: #fff;
  border-color: #fff;
  transition: box-shadow .3s;
  box-shadow: 0 0 0 0 #3e3e3e;
}

.btn-normal-02:before {
  background: #ffb208;
}

.btn-normal-02:after {
  display: none;
}

@media screen and (min-width: 769px) {
  .btn-normal-02:hover {
    border-color: #ffca55;
    box-shadow: 0 0 10px 5px #3a3a3a;
    color: #333;
  }
  .btn-normal-02:hover:before {
    opacity: 1;
    height: 105%;
  }
}

/* btn-normal-03
---------------------------------------------------------- */
.btn-normal-03 {
  background: #003399;
  color: #fff;
}

.btn-normal-03:before {
  background: #fff;
}

.btn-normal-03:after {
  background: url(/inc/image/ico_link_arrow_01_on.png) no-repeat;
  background-size: 14px 14px;
}

@media screen and (min-width: 769px) {
  .btn-normal-03:hover {
    color: #393939;
  }
  .btn-normal-03:hover:before {
    opacity: 1;
    height: 105%;
  }
  .btn-normal-03:hover:after {
    background: url(/inc/image/ico_link_arrow_01_off.png) no-repeat;
    background-size: 14px 14px;
  }
}

/* btn-small-01
---------------------------------------------------------- */
.btn-small-01, .btn-small-02 {
  width: 200px;
  padding: 5px 30px 5px 20px;
  border-width: 1px;
  font-size: 14px;
  font-size: 1.4rem;
}

@media screen and (max-width: 768px) {
  .btn-small-01, .btn-small-02 {
    width: 100%;
  }
}

.btn-small-01.btn-lgr, .btn-small-02.btn-lgr {
  width: 320px;
}

@media screen and (max-width: 768px) {
  .btn-small-01.btn-lgr, .btn-small-02.btn-lgr {
    width: 100%;
  }
}

/* btn-small-02
---------------------------------------------------------- */
.btn-small-02 {
  background: #fff;
  border-color: #fff;
}

.btn-small-02:before {
  background: #3b5280;
}

/* btn-icon-mail
---------------------------------------------------------- */
.btn-icon-mail span {
  padding-left: 28px;
}

.btn-icon-mail span:before {
  position: absolute;
  top: 5px;
  left: 0;
  width: 20px;
  height: 16px;
  background: url(/inc/image/ico_mail_01_off.png) no-repeat;
  content: "";
}

@media screen and (max-width: 768px) {
  .btn-icon-mail span {
    padding-left: 25px;
  }
  .btn-icon-mail span:before {
    top: 4px;
    width: 17px;
    height: 14px;
    background: url(/inc/image/ico_mail_01_sp.png) no-repeat;
    background-size: 17px 14px;
  }
}

@media screen and (min-width: 769px) {
  .btn-icon-mail:hover span:before {
    background: url(/inc/image/ico_mail_01_on.png) no-repeat;
  }
}

/* btn-icon-download
---------------------------------------------------------- */
.btn-icon-download span {
  padding-left: 25px;
}

.btn-icon-download span:before {
  position: absolute;
  top: 4px;
  left: 0;
  width: 18px;
  height: 18px;
  background: url(/inc/image/ico_download_01_off.png) no-repeat;
  content: "";
}

@media screen and (max-width: 768px) {
  .btn-icon-download span {
    padding-left: 22px;
  }
  .btn-icon-download span:before {
    top: 3px;
    width: 15px;
    height: 15px;
    background: url(/inc/image/ico_download_01_sp.png) no-repeat;
    background-size: 15px 15px;
  }
}

@media screen and (min-width: 769px) {
  .btn-icon-download:hover span:before {
    background: url(/inc/image/ico_download_01_on.png) no-repeat;
  }
}

/* btn-get-adobe
---------------------------------------------------------- */
.btn-get-adobe {
  -webkit-transition: opacity 0.15s ease 0s;
  transition: opacity 0.15s ease 0s;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.btn-get-adobe:hover {
  opacity: 0.7;
}

@media screen and (max-width: 768px) {
  .btn-get-adobe:hover {
    opacity: 1;
  }
}

.btnArea {
  padding: 15px 0;
  margin: 1em 0;
  background-color: #f9f9f9;
}

/* ==========================================================
 list
========================================================== */
/* list-bullet-01
---------------------------------------------------------- */
.list-bullet-01 > li, .article-product .tab ul > li, .list-bullet-02 > li {
  position: relative;
  margin-bottom: 5px;
  padding-left: 20px;
}

.list-bullet-01 > li:last-child, .article-product .tab ul > li:last-child, .list-bullet-02 > li:last-child {
  margin-bottom: 0;
}

.list-bullet-01 > li:before, .article-product .tab ul > li:before, .list-bullet-02 > li:before {
  position: absolute;
  top: 9px;
  left: 0;
  width: 7px;
  height: 7px;
  background: #003399;
  border-radius: 30px;
  content: "";
}

@media screen and (max-width: 768px) {
  .list-bullet-01 > li, .article-product .tab ul > li, .list-bullet-02 > li {
    padding-left: 15px;
  }
  .list-bullet-01 > li:before, .article-product .tab ul > li:before, .list-bullet-02 > li:before {
    top: 8px;
    width: 6px;
    height: 6px;
  }
}

/* list-bullet-02
---------------------------------------------------------- */
.list-bullet-02 > li {
  padding-left: 15px;
}

.list-bullet-02 > li:before {
  top: 9px;
  left: 0;
  width: 6px;
  height: 6px;
  background: #000;
  border-radius: 20px;
}

@media screen and (max-width: 768px) {
  .list-bullet-02 > li {
    padding-left: 10px;
  }
  .list-bullet-02 > li:before {
    top: 8px;
    width: 4px;
    height: 4px;
  }
}

/* list-number-01
---------------------------------------------------------- */
.list-number-01, .article-product .tab ol {
  counter-reset: section;
}

.list-number-01 > li, .article-product .tab ol > li {
  position: relative;
  margin-top: 10px;
  margin-bottom: 10px;
  padding-left: 20px;
}

.list-number-01 > li:last-child, .article-product .tab ol > li:last-child {
  margin-bottom: 0;
}

.list-number-01 > li:before, .article-product .tab ol > li:before {
  position: absolute;
  top: 0;
  left: 0;
  color: #003399;
  counter-increment: section;
  content: counter(section) ".";
}

.list-number-01 h4, .article-product .tab ol h4 {
  font-weight: bold;
}

/* list-note-01
---------------------------------------------------------- */
.list-note-01 > li {
  position: relative;
  margin-bottom: 8px;
  padding-left: 18px;
  font-size: 13px;
  font-size: 1.3rem;
}

.list-note-01 > li:before {
  position: absolute;
  top: 0;
  left: 0;
  color: #003399;
  content: "※";
}

@media screen and (max-width: 768px) {
  .list-note-01 > li {
    font-size: 12px;
    font-size: 1.2rem;
  }
}

/* list-card-links
---------------------------------------------------------- */
@media screen and (min-width: 769px) {
  .list-card-links.type-3-col li {
    width: 33.33%;
  }
  .list-card-links.type-3-col li:nth-child(3n+4) {
    clear: left;
  }
  .list-card-links.type-4-col li {
    width: 25%;
  }
  .list-card-links.type-4-col li:nth-child(4n+1) {
    clear: left;
  }
  .list-card-links.type-4-col li:nth-child(4n+1) .detail {
    border-left: none;
  }
  .list-card-links.type-4-col li .title {
    margin-top: -5px;
  }
}

@media screen and (min-width: 769px) and (max-width: 768px) {
  .list-card-links.type-4-col li .title {
    margin-top: 0;
  }
}

@media screen and (min-width: 769px) {
  .list-card-links.type-5-col li {
    width: 20%;
  }
  .list-card-links.type-5-col li:nth-child(5n+1) {
    clear: left;
  }
  .list-card-links.type-5-col li:nth-child(5n+1) .detail {
    border-left: none;
  }
  .list-card-links.type-5-col li a:hover {
    background-color: #fff;
  }
  .list-card-links.type-5-col li h4 {
    font-size: 16px;
    font-size: 1.1em;
    text-align: center;
  }
}

@media screen and (min-width: 769px) and (max-width: 768px) {
  .list-card-links.type-5-col {
    margin-top: 0;
  }
}

@media screen and (max-width: 768px) {
  .list-card-links {
    margin-bottom: 10px;
  }
}

.list-card-links li {
  float: left;
  padding-left: 1px;
  margin: 20px 0;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.2);
  transition: box-shadow 0.25s;
}

.list-card-links li:hover {
  position: relative;
  z-index: 10;
  box-shadow: 0 0 25px 0 rgba(0, 0, 0, 0.2);
}

.list-card-links li:hover .title {
  color: #00297a;
}

.list-card-links li:hover .detail {
  border-left-color: #fff;
}

@media screen and (max-width: 768px) {
  .list-card-links li {
    width: 50%;
  }
  .list-card-links li:nth-child(2n+3) {
    clear: left;
  }
}

.list-card-links figure.image {
  overflow: hidden;
}

.list-card-links figure.image img {
  width: 100%;
  transition: transform 0.25s ease-in-out;
}

.list-card-links .box-card {
  display: block;
}

@media screen and (min-width: 769px) {
  .list-card-links .box-card:hover img {
    transform: scale(1.04);
  }
}

.list-card-links .title {
  margin-bottom: 16px;
  font-size: 1.2em;
  font-weight: 500;
}

.list-card-links .title:before {
  top: 10px;
}

@media screen and (max-width: 768px) {
  .list-card-links .title {
    margin-bottom: 10px;
    font-size: 13px;
    font-size: 1.5rem;
  }
  .list-card-links .title:before {
    top: 6px;
    width: 8px;
    height: 8px;
  }
}

.list-card-links .detail {
  padding: 10px;
}

@media screen and (max-width: 768px) {
  .list-card-links .detail {
    padding-left: 0;
    border-left: 0;
  }
}

.list-card-links .text {
  font-size: 14px;
  font-size: 1.4rem;
  line-height: 1.7;
}

@media screen and (max-width: 768px) {
  .list-card-links .text {
    font-size: 12px;
    line-height: 1.3;
  }
}

/* ==========================================================
 form
========================================================== */
/* table-contact-form
---------------------------------------------------------- */
.table-contact-form {
  width: 100%;
  margin-bottom: 60px;
  border-collapse: collapse;
  text-align: left;
}

@media screen and (max-width: 768px) {
  .table-contact-form {
    margin-bottom: 40px;
  }
  .table-contact-form tr:last-child td {
    border-bottom: 1px solid #ccc;
  }
}

.table-contact-form th {
  width: 270px;
  padding: 20px 10px 20px 20px;
  background: #f5f5f5;
  border: 1px solid #ccc;
  font-weight: 500;
  vertical-align: middle;
}

@media screen and (max-width: 768px) {
  .table-contact-form th {
    display: block;
    width: auto;
    padding: 10px 15px;
    border-bottom: 0;
  }
}

.table-contact-form td {
  padding: 15px;
  border: 1px solid #ccc;
  vertical-align: middle;
}

@media screen and (max-width: 768px) {
  .table-contact-form td {
    display: block;
    width: auto;
    padding: 15px;
    border-bottom: 0;
  }
}

.table-contact-form tr:nth-child(even) td {
  background: #fbfbfb;
}

@media screen and (max-width: 768px) {
  .table-contact-form tr:nth-child(even) td {
    background: none;
  }
}

.table-contact-form .txt-mandatory, .table-contact-form .txt-optional {
  position: relative;
  display: block;
  padding-right: 60px;
}

.table-contact-form .txt-mandatory:after, .table-contact-form .txt-optional:after {
  position: absolute;
  top: 50%;
  right: 0;
  display: block;
  width: 70px;
  padding: 4px 0 5px;
  background: #003399;
  border-radius: 3px;
  color: #fff;
  font-weight: 400;
  font-size: 12px;
  font-size: 1.2rem;
  text-align: center;
  line-height: 1;
  transform: translateY(-50%);
  content: "Required";
}

.table-contact-form .txt-optional:after {
  background: #fff;
  border: 1px solid #003399;
  color: #003399;
  content: "Any";
}

.table-contact-form span {
  font-size: 75%;
}

/* box-input-postcode
---------------------------------------------------------- */
.box-input-postcode .box-inner {
  margin-bottom: 12px;
}

.box-input-postcode .list-input {
  display: table;
  float: left;
}

@media screen and (max-width: 768px) {
  .box-input-postcode .list-input {
    float: none;
    margin-bottom: 12px;
  }
}

.box-input-postcode .list-input li {
  display: table-cell;
  padding: 0 2px;
  vertical-align: middle;
  text-align: center;
}

.box-input-postcode .input-short {
  width: 80px;
}

.box-input-postcode .input-long {
  width: 500px;
}

@media screen and (max-width: 768px) {
  .box-input-postcode .input-long {
    width: 100%;
  }
}

.box-input-postcode .button {
  float: left;
  width: 185px;
  margin: -3px 0 0 15px;
  padding-top: 6px;
  padding-bottom: 6px;
  font-size: 12px;
  font-size: 1.2rem;
}

@media screen and (max-width: 768px) {
  .box-input-postcode .button {
    float: none;
    width: 194px;
    margin: 0;
  }
}

/* box-input-mail
---------------------------------------------------------- */
.box-input-mail ul {
  display: table;
  width: 100%;
  margin-bottom: 12px;
}

@media screen and (max-width: 768px) {
  .box-input-mail ul {
    display: block;
  }
}

.box-input-mail ul li {
  display: table-cell;
  vertical-align: middle;
}

.box-input-mail ul li:first-child {
  width: 480px;
}

@media screen and (max-width: 768px) {
  .box-input-mail ul li {
    display: block;
  }
  .box-input-mail ul li:first-child {
    width: 100%;
  }
}

.box-input-mail ul .input-long {
  width: 100%;
}

.box-input-mail ul .txt-remark {
  padding-left: 15px;
  font-size: 13px;
  font-size: 1.3rem;
}

@media screen and (max-width: 768px) {
  .box-input-mail ul .txt-remark {
    padding: 5px 0 0;
    font-size: 11px;
    font-size: 1.1rem;
  }
}

/* form-error-01
---------------------------------------------------------- */
.form-error-01 {
  border-color: #ff0000 !important;
}

/* form-error-text-01
---------------------------------------------------------- */
.form-error-text-01 {
  margin-top: 7px;
  padding-left: 20px;
  background: url(/inc/image/ico_error_01.png) left center no-repeat;
  background-size: 16px 15px;
  color: #eb1b1b;
}

/* form-selectbox-01
---------------------------------------------------------- */
.form-selectbox-01 {
  width: 290px;
  height: 33px;
  padding: 0 0 0 12px;
  border-radius: 3px;
  border: 1px solid #ccc;
}

@media screen and (max-width: 768px) {
  .form-selectbox-01 {
    width: 100%;
  }
}

/* form-input-01
---------------------------------------------------------- */
.form-input-01 {
  width: 188px;
  padding: 4px 12px;
  border: 1px solid #ccc;
  border-radius: 3px;
  color: inherit;
}

@media screen and (max-width: 768px) {
  .form-input-01 {
    width: 100%;
  }
}

.form-input-01::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #717171;
  opacity: 1;
}

.form-input-01::-moz-placeholder {
  /* Firefox 19+ */
  color: #717171;
  opacity: 1;
}

.form-input-01:-ms-input-placeholder {
  /* IE 10+ */
  color: #717171;
  opacity: 1;
}

.form-input-01:-moz-placeholder {
  /* Firefox 18- */
  color: #717171;
  opacity: 1;
}

.form-input-01.placeholdersjs {
  color: #717171;
  opacity: 1;
}

/* form-textarea-01
---------------------------------------------------------- */
.form-textarea-01 {
  width: 100%;
  height: 230px;
  padding: 4px 12px;
  border: 1px solid #ccc;
  border-radius: 3px;
  color: inherit;
}

/* ==========================================================
 icon
========================================================== */
/* ico-new
---------------------------------------------------------- */
.ico-new:after {
  position: relative;
  top: 0;
  display: inline-block;
  padding: 3px 10px;
  margin-left: 6px;
  border: 1px solid #003399;
  background-color: #FFF;
  border-radius: 3px;
  color: #003399;
  font-size: 12px;
  font-size: 1.2rem;
  line-height: 1;
  text-align: center;
  text-decoration: none;
  content: "NEW";
}

/* ico-pdf
---------------------------------------------------------- */
.ico-pdf:after {
  position: relative;
  display: inline-block;
  width: 17px;
  height: 20px;
  margin-left: 6px;
  background: url(/inc/image/ico_pdf_01.png) no-repeat;
  background-size: 17px 20px;
  content: "";
}

/* ico-blank
---------------------------------------------------------- */
.ico-blank:after {
  content: "";
  position: relative;
  display: inline-block;
  width: 16px;
  height: 14px;
  margin-left: 6px;
  background: url(/inc/image/top/ico_blank_01.png) no-repeat;
  background-size: 16px 14px;
  vertical-align: top;
}

/* pagetop
---------------------------------------------------------- */
.btn-cmn-pagetop {
  position: fixed;
  right: 10px;
  bottom: 10px;
  z-index: -1;
  opacity: 0;
  transition: opacity 0.2s ease-in;
}

.btn-cmn-pagetop.is-visible {
  z-index: 4;
  opacity: 1;
}

.btn-cmn-pagetop.is-stoped {
  position: absolute;
  top: -42px;
  right: 10px;
}

.btn-cmn-pagetop .button {
  display: block;
  width: 32px;
  height: 32px;
  background: url(/inc/image/btn_pagetop.png) no-repeat;
  background-size: 32px 32px;
  -webkit-transition: opacity 0.15s ease 0s;
  transition: opacity 0.15s ease 0s;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.btn-cmn-pagetop .button:hover {
  opacity: 0.7;
}

@media screen and (max-width: 768px) {
  .btn-cmn-pagetop .button:hover {
    opacity: 1;
  }
}

html {
  font-size: 62.5%;
}

body {
  font-family: "Noto Sans", "Hiragino Kaku Gothic Pro", "Meiryo", sans-serif;
  color: #444;
  font-size: 15px;
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 1.7;
  -webkit-text-size-adjust: 100%;
}

@media screen and (max-width: 768px) {
  body {
    font-size: 13px;
    font-size: 1.3rem;
    line-height: 1.7;
  }
}

@media screen and (min-width: 0\0) {
  body {
    font-family: "Noto Sans", "Hiragino Kaku Gothic Pro", "Meiryo", sans-serif;
  }
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  body {
    font-family: "Noto Sans", "Hiragino Kaku Gothic Pro", "Meiryo", sans-serif;
  }
}

input, textarea, select, button {
  font-family: "Noto Sans", "Hiragino Kaku Gothic Pro", "Meiryo", sans-serif;
}

@media screen and (min-width: 0\0) {
  input, textarea, select, button {
    font-family: "Noto Sans", "Hiragino Kaku Gothic Pro", "Meiryo", sans-serif;
  }
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  input, textarea, select, button {
    font-family: "Noto Sans", "Hiragino Kaku Gothic Pro", "Meiryo", sans-serif;
  }
}

/* ==========================================================
 html5 elements
========================================================== */
article, aside, figure, figcaption, footer, header, nav, section {
  display: block;
}

/* ==========================================================
 base Link
========================================================== */
a {
  color: #444;
  text-decoration: none;
}

a:hover, a:active, a:focus {
  color: #00297a;
  text-decoration: none;
}

p > a:hover, p > a:active, p > a:focus {
  color: #003399;
}

.contents:before, .contents:after {
  content: "";
  display: table;
}

.clearfix::after, .list-card-links::after {
  content: "";
  display: block;
  clear: both;
}

/* For IE 6/7
---------------------------------------------------------- */
.clearfix, .list-card-links, .contents {
  *zoom: 1;
}

/* tag-cmn-news
---------------------------------------------------------- */
.tag-cmn-news {
  display: inline-block;
  width: 112px;
  padding: 7px 0;
  border-radius: 3px;
  color: #fff;
  font-size: 12px;
  font-size: 1.1rem;
  font-weight: 700;
  text-align: center;
  line-height: 1;
}

.tag-cmn-news.color-01 {
  background: #4055a3;
}

.tag-cmn-news.color-02 {
  background: #8549a4;
}

.tag-cmn-news.color-03 {
  background: #3e8750;
}

.tag-cmn-news.color-04 {
  background: #2787a7;
}

.tag-cmn-news.color-05 {
  background: #e6e6e6;
  color: #4e4e4e;
}

.tag-cmn-news.color-co-01 {
  background: #003399;
}

.tag-cmn-news.color-co-02 {
  background: #097c25;
}

.tag-cmn-news.color-co-03 {
  background: #3e3e3e;
}

/* ==========================================================
 figure
========================================================== */
/* fig-cmn-01
---------------------------------------------------------- */
.fig-cmn-01 img {
  max-width: 100%;
}

.fig-cmn-01 .txt-caption {
  margin-top: 8px;
  font-size: 14px;
  font-size: 1.4rem;
  text-align: left;
}

.fig-cmn-01 .box-inner {
  display: inline-block;
}

@media screen and (max-width: 768px) {
  .fig-cmn-01 .txt-caption {
    font-size: 12px;
    font-size: 1.2rem;
  }
}

/* fig-cmn-02
---------------------------------------------------------- */
.fig-cmn-02 {
  display: table;
  width: 100%;
}

.fig-cmn-02 .image {
  display: table-cell;
  width: 330px;
}

@media screen and (max-width: 768px) {
  .fig-cmn-02 .image {
    display: block;
    width: auto;
    max-width: 330px;
    margin: 0 auto;
    text-align: center;
  }
}

.fig-cmn-02 .image img {
  max-width: 100%;
}

.fig-cmn-02 .txt-caption {
  display: table-cell;
  font-size: 18px;
  font-size: 1.8rem;
  vertical-align: middle;
  text-align: center;
}

@media screen and (max-width: 768px) {
  .fig-cmn-02 .txt-caption {
    display: block;
    padding-top: 5px;
    font-size: 16px;
    font-size: 1.6rem;
    text-align: center;
  }
}

/* ==========================================================
 style
========================================================== */
.s-full-h {
  height: 100%;
}

.s-fw-n {
  font-weight: normal;
}

.s-fw-b {
  font-weight: bold;
}

.s-ta-l {
  text-align: left !important;
}

.s-ta-c {
  text-align: center !important;
}

.s-ta-r {
  text-align: right !important;
}

.s-va-t {
  vertical-align: top !important;
}

.s-va-m {
  vertical-align: middle !important;
}

.s-va-b {
  vertical-align: bottom !important;
}

.s-ov-h {
  overflow: hidden;
}

.s-clear {
  clear: both;
}

.s-hide {
  display: none;
}

.s-txt-hide {
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
}

.s-bg-n {
  background: none !important;
}

.s-mt-00 {
  margin-top: 0 !important;
}

.s-mb-00 {
  margin-bottom: 0 !important;
}

.s-mb-05 {
  margin-bottom: 5px !important;
}

.s-mb-10 {
  margin-bottom: 10px !important;
}

.s-mb-15 {
  margin-bottom: 15px !important;
}

.s-mb-20 {
  margin-bottom: 20px !important;
}

.s-mb-35 {
  margin-bottom: 35px !important;
}

.s-ml-00 {
  margin-left: 0 !important;
}

.s-mr-00 {
  margin-right: 0 !important;
}

/* ==========================================================
 rwd switch
========================================================== */
/* pc
---------------------------------------------------------- */
.only-sp, .no-pc {
  display: none !important;
}

/* sp
---------------------------------------------------------- */
@media screen and (max-width: 768px) {
  .only-pc, .no-sp {
    display: none !important;
  }
  .only-sp, .no-pc {
    display: block !important;
  }
  img.only-sp, img.no-pc {
    display: inline-block !important;
  }
}

/* ==========================================================
 parts page
========================================================== */
.sec-part-01 {
  margin: 0 auto 40px;
}

@media screen and (max-width: 768px) {
  .sec-part-01 {
    width: 100%;
  }
}

.ttl-part-01 {
  margin-bottom: 30px;
  color: #003399;
  border-bottom: 1px solid #e6e6e6;
  font-size: 22px;
  font-size: 2.2rem;
  font-weight: 700;
}

/* ==========================================================
 parts product page
========================================================== */
/* main-product
---------------------------------------------------------- */
.main-product {
  margin-bottom: 40px;
}

@media screen and (max-width: 768px) {
  .main-product {
    margin-bottom: 30px;
  }
}

.main-product .slider-wrap {
  position: relative;
  margin-bottom: 20px;
}

.main-product .slider-wrap img {
  width: 100%;
}

.main-product .slider-wrap .slick-dots {
  position: absolute;
  bottom: 18px;
  left: 50%;
  transform: translateX(-50%);
  overflow: hidden;
}

.main-product .slider-wrap .slick-dots li {
  float: left;
  padding: 0 3px;
  outline: none;
}

.main-product .slider-wrap .slick-dots li.slick-active button {
  background-color: #f6ff00;
}

.main-product .slider-wrap .slick-dots button {
  display: block;
  width: 8px;
  height: 8px;
  background-color: rgba(255, 255, 255, 0.4);
  border-radius: 50%;
  outline: none;
  overflow: hidden;
  text-indent: -9999px;
}

.main-product .heading {
  position: relative;
  padding-bottom: 10px;
  margin-bottom: 10px;
  border-bottom: 1px solid #dadada;
}

@media screen and (min-width: 769px) {
  .main-product .heading {
    padding-right: 120px;
  }
}

@media screen and (max-width: 768px) {
  .main-product .heading {
    padding-bottom: 5px;
    margin-bottom: 5px;
  }
}

.main-product .heading .name-01 {
  display: block;
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 1.4;
}

@media screen and (max-width: 768px) {
  .main-product .heading .name-01 {
    font-size: 13px;
    font-size: 1.3rem;
  }
}

.main-product .heading .name-02 {
  display: block;
  font-size: 25px;
  font-size: 2.5rem;
}

@media screen and (max-width: 768px) {
  .main-product .heading .name-02 {
    font-size: 18px;
    font-size: 1.8rem;
  }
}

@media screen and (min-width: 769px) {
  .main-product .heading .code {
    position: absolute;
    bottom: 0;
    right: 0;
    padding-bottom: 10px;
    font-size: 16px;
    font-size: 1.6rem;
  }
}

@media screen and (max-width: 768px) {
  .main-product .heading .code {
    font-size: 13px;
    font-size: 1.3rem;
    text-align: right;
  }
}

.main-product .properties {
  font-size: 18px;
  font-size: 1.8rem;
  color: #003399;
}

@media screen and (max-width: 768px) {
  .main-product .properties {
    font-size: 16px;
    font-size: 1.6rem;
  }
}

.main-product .list-btns {
  margin-top: 25px;
  padding-right: 10px;
  text-align: right;
}

@media screen and (max-width: 768px) {
  .main-product .list-btns {
    margin-top: 20px;
  }
}

.main-product .list-btns li {
  display: inline-block;
  margin-left: 6px;
}

@media screen and (max-width: 768px) {
  .main-product .list-btns li {
    display: block;
    margin-top: 10px;
  }
}

/* article-product
---------------------------------------------------------- */
.article-product .list-tab-nav-01 a {
  padding: 20px 40px;
}

.article-product .tab table {
  margin: 10px 0 10px 0;
}

@media screen and (max-width: 768px) {
  .article-product .tab table {
    margin: 0 0 10px 0;
  }
}

.article-product .tab table th, .article-product .tab table td {
  padding: 6px;
}

.article-product .tab ol, .article-product .tab ul {
  font-size: 85%;
}

.article-product .ttl-cmn-01 {
  overflow: hidden;
}

@media screen and (min-width: 769px) {
  .article-product .ttl-cmn-01 {
    margin-bottom: 30px;
  }
}

@media screen and (max-width: 768px) {
  .article-product .ttl-cmn-01 {
    font-size: 18px;
    font-size: 1.8rem;
  }
}

.article-product .ttl-cmn-02 {
  overflow: hidden;
}

@media screen and (min-width: 769px) {
  .article-product .ttl-cmn-02 {
    margin-bottom: 20px;
  }
}

.article-product .box-text-image {
  overflow: hidden;
  margin-bottom: 30px;
}

@media screen and (max-width: 768px) {
  .article-product .box-text-image {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 25px;
  }
}

.article-product .box-text-image .fig-cmn-01 {
  position: relative;
  padding-top: 5px;
  z-index: 1;
  background-color: #fff;
  max-width: 450px;
}

@media screen and (max-width: 768px) {
  .article-product .box-text-image .fig-cmn-01 {
    width: 100%;
    order: 2;
    text-align: center;
  }
  .article-product .box-text-image .fig-cmn-01 img {
    max-width: 100%;
  }
}

@media screen and (min-width: 769px) {
  .article-product .box-text-image .fig-cmn-01.is-right {
    float: right;
    padding-left: 20px;
  }
}

@media screen and (min-width: 769px) {
  .article-product .box-text-image .fig-cmn-01.is-left {
    float: left;
    padding-right: 20px;
  }
}

.article-product .box-text-image .fig-cmn-01 .txt-caption {
  margin-bottom: 5px;
  text-align: center;
}

@media screen and (max-width: 768px) {
  .article-product .box-text-image .detail {
    width: 100%;
    order: 1;
    margin-bottom: 15px;
  }
}

.article-product .box-text-image .detail p {
  line-height: 2;
}

.article-product .sec-products-01 {
  margin-top: 50px;
  margin-bottom: 50px;
}

@media screen and (max-width: 768px) {
  .article-product .sec-products-01 {
    margin-bottom: 30px;
  }
}

.article-product .box-image {
  margin-bottom: 40px;
  text-align: center;
}

@media screen and (max-width: 768px) {
  .article-product .box-image {
    margin-bottom: 20px;
  }
}

.article-product .box-image img {
  max-width: 100%;
  margin: 0 auto;
}

.article-product .box-image.full img {
  width: 100%;
  max-width: none;
}

.article-product .list-cards {
  overflow: hidden;
}

@media screen and (min-width: 769px) {
  .article-product .list-cards {
    margin: -20px -5px 0;
  }
}

@media screen and (min-width: 769px) {
  .article-product .list-cards li {
    float: left;
    width: 25%;
    margin-top: 20px;
    padding: 0 5px;
  }
}

@media screen and (max-width: 768px) {
  .article-product .list-cards li:not(:last-child) {
    margin-bottom: 15px;
  }
}

.article-product .list-cards li:nth-child(4n+1) {
  clear: left;
}

.article-product .list-cards .image {
  overflow: hidden;
  position: relative;
  z-index: 1;
  margin-bottom: 15px;
}

@media screen and (max-width: 768px) {
  .article-product .list-cards .image {
    margin-bottom: 10px;
  }
}

.article-product .list-cards .image img {
  width: 100%;
}

@media screen and (min-width: 769px) {
  .article-product .list-cards a .image img {
    transition: transform 0.2s ease-in-out;
  }
  .article-product .list-cards a:hover .image img {
    transform: scale(1.04);
  }
}

.article-product .list-cards .title {
  font-size: 16px;
  font-size: 1.1em;
  margin-bottom: 10px;
  font-weight: 500;
}

@media screen and (max-width: 768px) {
  .article-product .list-cards .title {
    margin-bottom: 5px;
    font-size: 1.4em;
  }
}

.article-product .list-cards .text {
  line-height: 1.6;
}
