/* ==========================================================
 button
========================================================== */
/* btn-normal-01
---------------------------------------------------------- */
.btn-normal-01, .btn-normal-02, .btn-normal-03,
.btn-small-01, .btn-small-02 {
  background-color: #fff;
  overflow: hidden;
  position: relative;
  display: block;
  width: 380px;
  margin: 0 auto;
  padding: 10px 0;
  border: 2px solid #002e8a;
  border-radius: 5px;
  font-weight: 500;
  color: #223968;
  text-align: center;
  transform: translateZ(0);
  box-shadow: 0 0 0 0 rgba(0,0,0,0);
  transition: box-shadow $headerFixedAnimationSpeed;

  &.inline{
    width: auto;
    display: inline-block;
    margin-right: 4px;
    padding-left: 10px;
    padding-right: 28px;
    @media screen and (max-width: 768px) {
      width: 100%;
    }
  }

  &.full {
    width: 100%;
  }

  @media screen and (max-width: 768px) {
    width: 100%;
    font-size: 14px;
    font-size: 1.4rem;
  }

  span {
    position: relative;
    z-index: 1;
    display: inline-block;
  }

  &:after {
    position: absolute;
    top: 50%;
    right: 8px;
    width: 14px;
    height: 14px;
    margin-top: -7px;
    background: url(/inc/image/ico_link_arrow_01_off.png) no-repeat;
    background-size: 14px 14px;
    content: "";
  }

  &:before {
    position: absolute;
    height: 0;
    left: 50%;
    top: 50%;
    width: 101%;
    z-index: -1;
    transition: opacity 0.2s ease, height 0.2s ease;
    // transform: translateY(-50%) translateX(-50%) translateZ(0) ;
    background-color: #002e8a;
    opacity: 0.5;
    content: "";

    @media screen and (max-width: 768px) {
      display: none;
    }
  }

  @media screen and (min-width: 769px) {
    &:hover {
      box-shadow: $hoverAnimationShadow;
      color: #fff;

      &:before {
        opacity: 1;
        height: 105%;
      }
      &:after {
        background: url(/inc/image/ico_link_arrow_01_on.png) no-repeat;
        background-size: 14px 14px;
      }
    }
  }
}

:root {
  .btn-normal-01, .btn-normal-02, .btn-normal-03,
  .btn-small-01, .btn-small-02 {
    &:before {
      transform: translateY(-50%) translateX(-50%) ;
    }
  }
}

$contactActiveColor: #ffb208;

/* btn-normal-02
---------------------------------------------------------- */
.btn-normal-02 {
  background: #fff;
  border-color: #fff;
  transition: box-shadow .3s;
  box-shadow: 0 0 0 0 #3e3e3e;

  &:before {
    background: $contactActiveColor;
  }
  &:after {
    display: none;
  }

  @media screen and (min-width: 769px) {
    &:hover {
      border-color: lighten($contactActiveColor,15);
      box-shadow: 0 0 10px 5px #3a3a3a;
      color: #333;
      &:before {
        opacity: 1;
        height: 105%;
      }
    }
  }
}

/* btn-normal-03
---------------------------------------------------------- */
.btn-normal-03 {
  background: $colorBlue01;
  color: #fff;

  &:before {
    background: #fff;
  }
  &:after {
    background: url(/inc/image/ico_link_arrow_01_on.png) no-repeat;
    background-size: 14px 14px;
  }

  @media screen and (min-width: 769px) {
    &:hover {
      color: #393939;
      &:before {
        opacity: 1;
        height: 105%;
      }
      &:after {
        background: url(/inc/image/ico_link_arrow_01_off.png) no-repeat;
        background-size: 14px 14px;
      }
    }
  }
}

/* btn-small-01
---------------------------------------------------------- */
.btn-small-01, .btn-small-02 {
  width: 200px;
  padding: 5px 30px 5px 20px;
  border-width: 1px;
  font-size: 14px;
  font-size: 1.4rem;

  @media screen and (max-width: 768px) {
    width: 100%;
  }
  
  //長さ調整用
  &.btn-lgr{
    width: 320px;
    @media screen and (max-width: 768px){
      width: 100%;
    }
  }
}

/* btn-small-02
---------------------------------------------------------- */
.btn-small-02 {
  background: #fff;
  border-color: #fff;

  &:before {
    background: #3b5280;
  }
}

/* btn-icon-mail
---------------------------------------------------------- */
.btn-icon-mail {
  span {
    padding-left: 28px;
    &:before {
      position: absolute;
      top: 5px;
      left: 0;
      width: 20px;
      height: 16px;
      background: url(/inc/image/ico_mail_01_off.png) no-repeat;
      content: "";
    }

    @media screen and (max-width: 768px) {
      padding-left: 25px;
      &:before {
        top: 4px;
        width: 17px;
        height: 14px;
        background: url(/inc/image/ico_mail_01_sp.png) no-repeat;
        background-size: 17px 14px;
      }
    }
  }

  @media screen and (min-width: 769px) {
    &:hover span:before {
      background: url(/inc/image/ico_mail_01_on.png) no-repeat;
    }
  }
}

/* btn-icon-download
---------------------------------------------------------- */
.btn-icon-download {
  span {
    padding-left: 25px;
    &:before {
      position: absolute;
      top: 4px;
      left: 0;
      width: 18px;
      height: 18px;
      background: url(/inc/image/ico_download_01_off.png) no-repeat;
      content: "";
    }

    @media screen and (max-width: 768px) {
      padding-left: 22px;
      &:before {
        top: 3px;
        width: 15px;
        height: 15px;
        background: url(/inc/image/ico_download_01_sp.png) no-repeat;
        background-size: 15px 15px;
      }
    }
  }

  @media screen and (min-width: 769px) {
    &:hover span:before {
      background: url(/inc/image/ico_download_01_on.png) no-repeat;
    }
  }
}

/* btn-get-adobe
---------------------------------------------------------- */
.btn-get-adobe {
  @include hoverOpacity();
}


.btnArea{
  padding: 15px 0;
  margin: 1em 0;
  background-color: #f9f9f9;
}
